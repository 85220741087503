import React, { useEffect } from "react";
import Header from "../components/layout/Header";
import ImamPosting from "../assets/images/about-us/ImamPosition.pdf";
import ResidentScholar from "../assets/images/about-us/ResidentScholar.pdf";
import Footer from "../components/layout/Footer";
import Banner from "../assets/images/about-us/AboutUsBanner.webp";
import AboutUsTabs from "../components/services/AboutUsTabs";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    <Helmet>
      <title>
        About Us | Huntsville Islamic Center
      </title>
    </Helmet>
    <section id="website-responsive" className="px-0">
    <section className="container-fluid bg-common-banner"  style={{backgroundImage:`url(${Banner})`}}>
    <Header/>
    <div className="fs-banner-title text-white fw-500 text-center py-5">
      <div className="py-md-5 mb-md-5">
      About Us
      <div className="py-3 py-lg-4" />
      </div>
    </div>
    </section>
    <div className="py-4 py-md-5" />
    <AboutUsTabs/>
    <div className="py-4 py-md-5" />
    <section className="container-fluid text-md-center col-11 col-lg-9 mx-auto bg-join-team py-5 shadow-ss-1 rounded-200">
          <div className="">
            <div className="lh-sm">
            <div className="fs-ss-48 fw-600 text-white text-center">
              Join the Team
            </div>
              </div>
            </div>
          <div className="row justify-content-center gap-md-4 text-center mt-5">
          <div className="col-11 col-md-6 col-lg-4 ">
              <a href={ImamPosting} target='_blank' className="d-block fs-ss-24 fw-500 hover-ss-donate rounded-3 py-2 mb-4 mb-md-0 mb-lg-4 text-black text-decoration-none">
                Imam Position
              </a>
          </div>
          <div className="col-11 col-md-6 col-lg-4">
              <a href={ResidentScholar} target='_blank' className="d-block fs-ss-24 fw-500 hover-ss-donate rounded-3 py-2 mb-4 text-black text-decoration-none">
                Resident Scholar Position
              </a>
          </div>
          </div>
        </section>
        <div className="py-4 py-md-5" />
    <Footer/>
    </section>
    </>
  );
}

export default AboutUs