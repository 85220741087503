import React from "react";

const MiddleBanner = (props) => {
  return (
    <>
    <section id="middle-banner" className="container-fluid text-white bg-middle-banner py-5" style={{backgroundImage:`url(${props.BgImage})`}}>
      <div className="text-center py-md-5">
        <div className="fs-middle-banner fw-500 text-ss-secondary lh-sm text-hidayatullah">
          {props.Title}
        </div>
        <div className="fs-sub-middle-banner fw-500 mt-2 mb-4">
          {props.SubTitle}
        </div>
         <a href="https://forms.gle/broFjS7cwYRk89Sd6" target='_blank' className="text-decoration-none rounded-5 border-0 btn-padding-middle pt-1 pb-2 hover-ss-donate fs-ss-20 text-dark fw-500">
          {props.BtnTitle}
        </a>
      </div>
    </section>
    </>
  );
}

export default MiddleBanner

