import React, { useEffect } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import Banner from "../assets/images/banners/Resources.webp";
import { Helmet } from "react-helmet";

const Resources = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Resources | Huntsville Islamic Center</title>
      </Helmet>
      <section id="website-responsive" className="px-0">
        <section
          className="container-fluid bg-common-banner"
          style={{ backgroundImage: `url(${Banner})` }}
        >
          <Header />
          <div className="fs-banner-title text-white fw-500 text-center py-5">
            <div className="py-md-5 mb-md-5">
              Resources
              <div className="py-3 py-lg-4" />
            </div>
          </div>
        </section>
        <div className="py-4 py-md-5" />
        <div className="container-fluid">
          <div className="row justify-content-center gap-md-4 text-center">
            <div className="col-11 col-md-5 col-lg-4">
              {/* Membership Form */}
              <a
                href="https://forms.gle/broFjS7cwYRk89Sd6"
                target="_blank"
                className="d-block fs-ss-24 fw-500 hover-ss-donate cursor-pointer rounded-3 py-3 shadow-ss-1-btn mb-4 mx-xxl-5 text-black text-decoration-none"
              >
                Membership Form
              </a>

              {/* Property Rental Form */}
              <a
                href="https://forms.gle/76YYTqqP4P9CJGJy9"
                target="_blank"
                className="d-block fs-ss-24 fw-500 hover-ss-donate cursor-pointer rounded-3 py-3 shadow-ss-1-btn mb-4 mx-xxl-5 text-black text-decoration-none"
              >
                Property Rental Form
              </a>

              {/* Board Nomination Form */}
              <a
                href="https://forms.gle/1FZW822p5k2fP5PBA"
                target="_blank"
                className="d-block fs-ss-24 fw-500 hover-ss-donate cursor-pointer rounded-3 py-3 shadow-ss-1-btn mb-4 mx-xxl-5 text-black text-decoration-none"
              >
                Board Nomination Form
              </a>

              {/* Hardship Form */}
              <a
                href="https://forms.gle/KJu1kzsSce6bTW958"
                target="_blank"
                className="d-block fs-ss-24 fw-500 hover-ss-donate cursor-pointer rounded-3 py-3 shadow-ss-1-btn mb-4 mx-xxl-5 text-black text-decoration-none"
              >
                Hardship Form
              </a>
            </div>

            <div className="col-11 col-md-5 col-lg-4">
              {/* Maintenance Form */}
              <a
                href="https://forms.gle/W1tvdmFhFUk8jFBX7"
                target="_blank"
                className="d-block fs-ss-24 fw-500 hover-ss-donate cursor-pointer rounded-3 py-3 shadow-ss-1-btn mb-4 mx-xxl-5 text-black text-decoration-none"
              >
                Maintenance Form
              </a>

              {/* Become a Volunteer Form */}
              <a
                href="https://forms.gle/rhS1j45artxrxUQG9"
                target="_blank"
                className="d-block fs-ss-24 fw-500 hover-ss-donate cursor-pointer rounded-3 py-3 shadow-ss-1-btn mb-4 mx-xxl-5 text-black text-decoration-none"
              >
                Become a Volunteer Form
              </a>

              {/* Schedule a Visit */}
              <a
                href="https://forms.gle/qfEWtWdHjTBNf5D7A"
                target="_blank"
                className="d-block fs-ss-24 fw-500 hover-ss-donate cursor-pointer rounded-3 py-3 shadow-ss-1-btn mb-4 mx-xxl-5 text-black text-decoration-none"
              >
                Schedule a Visit
              </a>

              {/*  Iftar Sponsorship */}
              <a
                href="https://forms.gle/H6m2zwP9gyqzUhhq7"
                target="_blank"
                className="d-block fs-ss-24 fw-500 hover-ss-donate cursor-pointer rounded-3 py-3 shadow-ss-1-btn mb-4 mx-xxl-5 text-black text-decoration-none"
              >
                 Iftar Sponsorship
              </a>
            </div>
          </div>
        </div>
        <div className="py-md-4" />
        <Footer />
      </section>
    </>
  );
};

export default Resources;
