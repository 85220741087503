import React, { useEffect } from "react";
import Header from "../components/layout/Header";
import MiddleBanner from "../components/common/MiddleBanner";
import Space from "../components/common/Space";
import Footer from "../components/layout/Footer";
import Img1 from "../assets/images/marriage-ceremonies/Img1.webp";
import Icon1 from "../assets/images/about-us/Icon1.webp";
import Icon2 from "../assets/images/about-us/Icon2.webp";
import Icon3 from "../assets/images/about-us/Icon3.webp";
import Icon4 from "../assets/images/about-us/Icon4.webp";
import Banner from "../assets/images/services/Banner.webp";
import BgImage from "../assets/images/about-us/AboutUsMiddleBanner.webp";
import BoardMembers from "../components/common/BoardMembers";
import AIAD from "../components/services/AIAD";
import UpcomingEvents from "../components/UpcomingEvents/UpcomingEvents";
import { Helmet } from "react-helmet";

const MarriageCeremonies = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <Helmet>
      <title>
      Marriage Ceremonies | Huntsville Islamic Center
      </title>
    </Helmet>
    <section id="website-responsive" className="px-0">
    <section className="container-fluid bg-common-banner"  style={{backgroundImage:`url(${Banner})`}}>
    <Header/>
    <div className="fs-banner-title text-white fw-500 text-center py-5">
      <div className="py-md-5 mb-md-5">
        Marriage Ceremonies
      <div className="py-3 py-lg-4" />
      </div>
    </div>
    </section>
    <div className="py-4 py-md-5" />
    <section className="container-fluid">
      <div className="col-11 col-lg-9 text-md-center mx-auto">
        <div className="fs-ss-22 fw-600">
        "And of His signs is that He has created for you, from yourselves, mates, so that you may find tranquility in them; and He placed between you affection and mercy. Indeed, in that are signs for a people who give thought." (Qur'an, 30:21)
        </div>
        <div className="fs-ss-18 fw-400 mt-3">
        At the Huntsville Islamic Center (HIC), we honor the sacred bond of marriage as a cornerstone of faith and community. Rooted in Islamic principles, the institution of marriage is more than a union—it is a journey of love, mercy, and companionship. A nikah conducted in a masjid reflects these values, offering a spiritual foundation for the couple’s new chapter. HIC is proud to facilitate Islamic marriage services, ensuring the process aligns with the teachings of Islam and fosters joy and blessings for all involved. 
        </div>
      </div>
    </section>
    
    <div className="py-4 py-md-5" />
    <div className="">
      <div className="lh-sm text-center">
        </div>
      <AIAD/>
    </div>

    <div className="py-4 py-md-5" />
    <section className="container-fluid">
      <div className="row justify-content-center align-items-center gy-4">
        <div className="col-11 col-md-4 d-none d-md-block">
          <img src={Img1} className="w-100 px-lg-5" alt="" />
        </div>
      <div className="ps-md-5 col-11 col-md-7 col-lg-5">
      <div className="lh-sm">
        <div className="fs-ss-40 fw-600">
        Why Choose HIC for Your Nikah? 
        </div>
        </div>
        <div className="fs-ss-18 fw-400 mt-3">
          <ul>
           <li> Guidance rooted in Islamic values and teachings. </li>
           <li> A serene and spiritual atmosphere to begin your marital journey. </li>
           <li> A welcoming community to celebrate your special day. </li>
          </ul>
          <div className="ps-md-2">
          For further inquiries or assistance, please reach out to us at <a className='text-decoration-none text-ss-primary fw-500' href="mailto:board@huntsvilleislamiccenter.org"> board@huntsvilleislamiccenter.org </a>  
          </div> 
        </div>
      </div>
      </div>
    </section>
    <div className="py-4 py-md-5" />
    <Footer/>
    </section>
    </>
  );
}

export default MarriageCeremonies