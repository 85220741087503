import React, {useEffect} from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { AccordionItem } from 'react-bootstrap';
import Header from '../components/layout/Header';
import Banner from "../assets/images/Banner.webp";
import Footer from '../components/layout/Footer';
import FAQsSection from '../components/common/FAQsSection';
import { Helmet } from "react-helmet";
 
const FAQs = (props) => {

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  return (
    <>
    <Helmet>
      <title>
        FAQs | Huntsville Islamic Center
      </title>
    </Helmet>
      {/* <Header /> */}
      <section className="container-fluid bg-common-banner"  style={{backgroundImage:`url(${Banner})`}}>
    <Header/>
    <div className="fs-banner-title text-white fw-500 text-center py-5">
      <div className="py-md-5 mb-md-5">
      FAQs
      <div className="py-3 py-lg-4" />
      </div>
    </div>
    </section>
      <section id='website-responsive' className="continer-fluid py-5 my-md-4">
      <div className="text-center pb-4 pb-md-5">
        <h2 className="fs-ss-35 fw-600 lh-sm px-5">
        Frequently Asked Questions (FAQs)
        </h2>
      </div>

    <section>
      <div className='container-fluid'>
          <div className='row'>
              <div className='col-md-8 mx-auto' style={{borderRadius:"30px"}}>
                  <FAQsSection
                    Title="What Services Does the Center Offer?  "
                    Para="We provide prayer services, educational programs, and community support activities. "
                    />

                    <FAQsSection
                    Title="How do I Find Daily and Jumu'ah Prayer Times?  "
                    Para="Visit our website's prayer schedule section for updated timings. "
                    />

                    <FAQsSection
                    Title="Is There a Weekend School for Children?  "
                    Para="Yes, we offer a weekend Islamic school for youth education. "
                    />

                    <FAQsSection
                    Title="How Do I Stay Informed About Events?  "
                    Para="Sign up for our newsletter for updates. "
                    />

                    <FAQsSection
                    Title="How Can I Join the Center’s Community Programs?  "
                    Para="Details about participation can be found in our events and programs section. "
                    />

                    <FAQsSection
                    Title="How Can I Support or Donate to the Center?  "
                    Para="Donation details are available on our Donate page. "
                    />

                    <FAQsSection
                    Title="How Do I Arrange a Visit or Tour of the Center?  "
                    Para="Contact us to set up a visitation time for yourself or your group at 256-721-1712 or send email to: Huntsville Islamic Center. If you have never visited a Mosque before, please consider reading these most frequently asked questions about the etiquette of visiting a mosque. The following are the most frequently asked questions about visiting a mosque. If you need any more clarification, please send an email to: Huntsville Islamic Center. "
                    />

                    <FAQsSection
                    Title="What should I wear? "
                    Para="It is most appropriate to wear modest, loose-fitting clothes. For men, it is better to wear long pants, and for women to wear pants or full-length skirts or dresses, with long sleeves. Muslim women typically wear a headscarf as well. "
                    />

                    <FAQsSection
                    Title="Why do we take our shoes off? "
                    Para="It is appropriate to remove one’s shoes before entering the prayer area at a mosque, so that the floors and carpets aren’t covered with dirt–after all, that is where people pray. "
                    />

                    <FAQsSection
                    Title="What are the foot sinks in the bathroom for? "
                    Para="Muslims are supposed to be in a state of physical purification before making the prayer, which includes washing the feet. "
                    />

                    <FAQsSection
                    Title="What happens when people join the prayer late? "
                    Para="They will join the prayer already in progress, and after the imam (leader of the prayer) has finished, they will complete what they missed. "
                    />

                    <FAQsSection
                    Title="How do Friday prayers work? "
                    Para="Friday is the day of congregational prayers for Muslims–so a short sermon followed by a short prayer at the mosque in congregation is substituted for the regular noon-time prayer. The service begins with the call to prayer, followed by a lecture (rather, two short lectures with a brief pause in the middle). After the lecture (called a khutbah), another call to prayer is made and the congregation stands to follow the imam in the prayer. "
                    />
                                      
                  </div>
                </div>
            </div>
        </section>
        </section>
      <Footer/>
      </>
  );
}

export default FAQs;
