import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import Banner from "../assets/images/banners/Calendar.webp";

const GoogleCalendar = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <Helmet>
      <title>
        Calendar | Huntsville Islamic Center
      </title>
    </Helmet>
    <section className="container-fluid bg-common-banner"  style={{backgroundImage:`url(${Banner})`}}>
    <Header/>
    <div className="fs-banner-title text-white fw-500 text-center py-5">
      <div className="py-md-5 mb-md-5">
        Calendar
      <div className="py-3 py-lg-4" />
      </div>
    </div>
    </section>
    <div className="py-4 py-md-5" />
    <div className="col-11 col-md-10 col-lg-8 mx-auto shadow-ss-1">
    <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&ctz=America%2FChicago&showPrint=0&src=c29jaWFsQGh1bnRzdmlsbGVpc2xhbWljY2VudGVyLm9yZw&color=%23D50000" width="100%" style={{height:"80vh"}} frameborder="0"/>
    </div>  
    <div className="py-4 py-md-5"/>
    <Footer/>
    </>
  );
}

export default GoogleCalendar