import React, { useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";

const EmailSubscribeFooter = () => {
  const [email, setEmail] = useState("");
  const [captchaToken, setCaptchaToken] = useState(null);
  const captchaRef = useRef(); // Reference for reCAPTCHA
  const formRef = useRef(); // Create a reference for the form

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token); // Update the token on reCAPTCHA completion
  };

  // Handle the form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Simple email validation
    if (!email) {
      toast.error("Please enter a valid email address.", {
        position: "bottom-left",
      });
      return;
    }

        // Validate CAPTCHA
        if (!captchaToken) {
          toast.error(
            <>
              <div className="text-start">
                Google reCAPTCHA verification failed, please try again later.
              </div>
            </>,
            { position: "bottom-left" }
          );
          return;
        }
    

    // Send email (using emailjs or any other service you prefer)
    emailjs
      .sendForm(
        "service_hic", // Replace with your EmailJS service ID
        "template_newsletter", // Replace with your EmailJS template ID
        formRef.current, // Use the form reference here
        "62L2S7468wThNb16U" // Replace with your EmailJS public key
      )
      .then(
        (response) => {
          toast.success("Thank you for subscribing to our newsletter!", {
            position: "bottom-left",
          });
          setEmail(""); // Reset email field after successful submission  
          captchaRef.current.reset(); // Reset reCAPTCHA
        },
        (error) => {
          console.log(error);
          toast.error("An error occurred while submitting the form.", {
            position: "bottom-left",
          });
        }
      );
  };

  return (
    <>
      <ToastContainer />
      <section className="container-fluid text-white">
        <div className="text-center">
          <div className="fs-middle-banner fw-500 text-ss-secondary lh-sm text-hidayatullah">
            Newsletter
          </div>
          <div className="fs-sub-middle-banner fw-500">
            Subscribe to our mailing list
          </div>
          <div className="mt-4">
          <a target="_blank" href="https://checkout.square.site/merchant/WKRSEAKCJHK6E/checkout/VHNIUNBCZZT77TEG77DKXULW" data-bs-toggle="modal" data-bs-target="#exampleModal" className="hover-ss-donate text-decoration-none bg-ss-secondary rounded-5 border-0 py-2 px-5 fs-ss-22 text-dark fw-500">
          Signup
          </a>
          </div>
          
          <div class="mt-5 mt-md-0 modal modal-lg fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body bg-above-footer py-5">
                    <div className="pb-4">
                    <div className="fs-ss-56 fw-500 text-ss-secondary lh-sm text-hidayatullah">
                      Newsletter
                    </div>
                    <div className="fs-sub-middle-banner fw-500">
                      Subscribe to our mailing list
                    </div>
                    </div>
                    
                    <form ref={formRef} onSubmit={handleSubmit}>
                    <div className="col-md-10 mt-2 mt-md-3 d-flex mx-auto bg-white">
                      <input
                      className="py-2 form-control input-focus rounded-0 border-0"
                      placeholder="Email"
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      />

                        <button
                        type="submit"
                        className="col-4 d-block d-md-none bg-ss-primary text-white rounded-0 border-0 py-1 fs-ss-20 fw-500 my-1 me-1"
                        >
                          Sign up
                        </button>
                        
                        <button
                        type="submit"
                        className="w-50 d-none d-md-block bg-ss-primary text-white rounded-0 border-0 py-1 fs-ss-20 fw-500 my-1 me-1"
                        >
                          Sign up
                        </button>
                  </div>
                  <div className="col-md-10 mx-auto text-start mt-4">
                    <ReCAPTCHA
                    sitekey="6LeRAMYqAAAAAPkJ56Nnqdgmmsm9QgnISv0tpiMX"
                    onChange={handleCaptchaChange}
                    ref={captchaRef}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        </div>
      </section>
    </>
  );
};

export default EmailSubscribeFooter;
