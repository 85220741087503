import React, { useEffect } from "react";
import Header from "../components/layout/Header";
import Banner from "../assets/images/banners/BurialServices.webp";
import { Helmet } from "react-helmet";
import BurialTabs from "../components/services/BurialTabs";
import Footer from "../components/layout/Footer";

const BurialServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <Helmet>
      <title>
      Burial Services | Huntsville Islamic Center
      </title>
    </Helmet>
    <section id="website-responsive" className="px-0">
    <section className="container-fluid bg-common-banner"  style={{backgroundImage:`url(${Banner})`}}>
    <Header/>
    <div className="fs-banner-title text-white fw-500 text-center py-5">
      <div className="py-md-5 mb-md-5">
        Burial Services
      <div className="py-3 py-lg-4" />
      </div>
    </div>
    </section>
    <div className="py-4 py-md-5" />
    <section className="container-fluid">
      <div className="col-11 col-lg-10 text-md-center mx-auto">
        <div className="fs-ss-22 fw-500">
        "Every soul will taste death, and you will only be given your full compensation on the Day of Resurrection. So whoever is drawn away from the Fire and admitted to Paradise, he has attained success. And what is the life of this world except the enjoyment of delusion?" 
        <br /> (Surat ‘Āli Imrān 3:185)
        </div>
        <div className="mt-3 fs-ss-18 fw-400">
        At the Huntsville Islamic Center (HIC), we are committed to assisting families during one of life's most profound moments of loss. Our team is here to guide you through the process with compassion and respect, ensuring all practices align with Islamic principles. 
        </div>
      </div>
    </section>
    <div className="py-4 py-md-5" />
    <div className="">
      <BurialTabs/>
    </div>

    <div className="py-4 py-md-5" />
    <Footer/>
    </section>
    </>
  );
}

export default BurialServices