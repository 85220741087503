import React, { useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Icon1 from "../../assets/images/about-us/Icon1.webp";
import Icon2 from "../../assets/images/about-us/Icon2.webp";
import Icon3 from "../../assets/images/about-us/Icon3.webp";
import Icon4 from "../../assets/images/about-us/Icon4.webp";
import Img1 from "../../assets/images/about-us/Img2.webp";
import Img2 from "../../assets/images/about-us/Img1.webp";
import PDF1 from "../../assets/images/about-us/HICBylaws.pdf";
import PDF2 from "../../assets/images/about-us/HICResolutions.pdf";
import { Link } from 'react-router-dom';

const AboutUsTabs = () => {

  return (
    <>
    <Tabs className="row justify-content-center gx-0 gy-5">

    <TabList className="fs-ss-30 fw-600 text-dark col-11 col-lg-3 ps-md-5">
      <div className="sticky-top">
      <Tab className="d-block py-3 cursor px-2 border-right "> 
          <div className=''>
           About Us
          </div>
      </Tab>

      <Tab className="d-block py-3 cursor px-2 border-right "> 
      <div className=''>
        Our Team
          </div>
      </Tab>

      <Tab className="d-block py-3 cursor px-2 border-right "> 
      <div className=''>
        Programs & Services
      </div>
      </Tab>

      <Tab className="d-block py-3 cursor px-2 border-right "> 
      <div className=''>
            Bylaws & Resolution
          </div>
      </Tab>
      </div>
    </TabList>

    <div className="col-11 col-lg-9">
    <TabPanel className="col-12 col-lg-10 mx-auto">
    <section className="container-fluid">
      <div className="">
        <div className="lh-sm">
        <div className="fs-ss-40 fw-600">
        A Community Centered Around Faith and Service 
        </div>
        <div className="text-ss-secondary fs-ss-22 fw-500">
        Empowering Individuals, Enriching Lives
        </div>
        </div>
        <div className="fs-ss-18 fw-400 mt-3">
        Welcome to the Huntsville Islamic Center (HIC), proudly serving the Muslim community throughout the Tennessee Valley region since 1990. Located in Huntsville, Alabama, we are dedicated to providing spiritual, educational, and social support to individuals and families in the area. 
        </div>
      </div>
    </section>
    <div className="py-4 py-md-5" />
    <section className="container-fluid">
      <div className="row justify-content-center align-items-center gy-4">
      <div className="col-12 col-md-6">
        <div className="fs-ss-40 fw-600">
        Our Mission 
        </div>
        <div className="text-ss-secondary fs-ss-22 fw-500">
        Building a Stronger Community Together
        </div>
        <div className="fs-ss-18 fw-400 text-start mt-3">
        Our mission - as individuals and as a community - is to live our faith as a peaceful united congregation, inspired by the teachings of Islam. We strive to be the anchor of a model Muslim community, governed democratically, with emphasis on shared values like tolerance, mutual respect, fellowship, and collaboration. Dedicated in service to God and humanity, we aim to foster unity, promote positive social change, and provide a welcoming environment for all. 
        </div>
        </div>
        <div className="col-11 col-md-6">
          <img src={Img2} className="w-100 px-md-5" alt="" />
        </div>
      </div>
    </section>
    {/* <section className="container-fluid">
      <div className="">
        <div className="lh-sm">
        <div className="fs-ss-40 fw-600">
       At Huntsville Islamic Center
        </div>
        <div className="text-ss-secondary fs-ss-22 fw-500">
        We offer
        </div>
        </div>
        <div className="fs-ss-18 fw-400 mt-3">
          <div className="">
          <div className="fs-ss-20 fw-600"> Prayers and Services: </div>
          <div className=""> We provide a peaceful and welcoming environment for worshippers to perform their daily prayers. Every Friday, we also host a Jumaah Khutbah (sermon) and congregational prayers. Our services also include performing Islamic weddings and funerals.  </div>
          <div className="py-2" />
          <div className="fs-ss-20 fw-600"> Educational Programs: </div>
          <div className=""> Our educational initiatives include a Weekend Islamic School, enriching Summer Camps, focused Quran and Arabic classes, as well as the Islamic Academy of Huntsville, a full-time Islamic school from preschool through 6th grade.  </div>
          <div className="py-2" />
          <div className="fs-ss-20 fw-600"> Youth Activities: </div>
          <div className=""> Our youth programs, such as Ansaar Brothers and Noor Sisters, provide a safe and engaging environment for young people of all ages to connect, learn, and thrive within the framework of Islam.  </div>
          <div className="py-2" />
          <div className="fs-ss-20 fw-600"> Revert Care Classes: </div>
          <div className=""> Designed to support and guide new Muslims as they navigate their spiritual journey, these classes offer essential knowledge about Islam and its practices while also helping individuals integrate and build a strong connection with the larger Muslim community.  </div>
          </div>
          <div className="pt-3">
          We are honored to have you and appreciate your interest in learning about us. We invite you to explore our website and learn more about our community. Please feel free to join us at our events and prayer services, and to contact us with any questions about Islam or Muslims.  
          </div>
        </div>
      </div>
    </section> */}
    </TabPanel>

    <TabPanel className="">
  <section className="container-fluid text-center">
    <div className="">
      <div className="lh-sm text-center pb-4 pb-md-0">
        <div className="fs-ss-40 fw-600">
          Board of Directors
        </div>
      </div>
      <div className="container-fluid pt-3 px-md-5">
        <div className="fs-ss-18 fw-500 my-md-3 row justify-content-center gap-3 pb-4 pb-lg-0">
          <div className="col-11 col-md-8 col-lg-3 shadow-ss-1-btn rounded-3 bg-grey2 py-2 px-3"> Omer Iqbal </div>
          <div className="col-11 col-md-8 col-lg-3 shadow-ss-1-btn rounded-3 bg-grey2 py-2 px-3"> President </div>
          <a href="mailto:president@huntsvilleislamiccenter.org" className="col-11 col-md-8 col-lg shadow-ss-1-btn rounded-3 bg-grey py-2 px-3 d-block text-dark text-decoration-none">
            president@huntsvilleislamiccenter.org
          </a>
        </div>

        <div className="fs-ss-18 fw-500 my-3 row justify-content-center gap-3 pb-4 pb-lg-0">
          <div className="col-11 col-md-8 col-lg-3 shadow-ss-1-btn rounded-3 bg-grey2 py-2 px-3"> Maisoun Zaza </div>
          <div className="col-11 col-md-8 col-lg-3 shadow-ss-1-btn rounded-3 bg-grey2 py-2 px-3"> Vice President </div>
          <a href="mailto:vicepresident@huntsvilleislamiccenter.org" className="col-11 col-md-8 col-lg shadow-ss-1-btn rounded-3 bg-grey py-2 px-3 d-block text-dark text-decoration-none">
            vicepresident@huntsvilleislamiccenter.org
          </a>
        </div>

        <div className="fs-ss-18 fw-500 my-3 row justify-content-center gap-3 pb-4 pb-lg-0">
          <div className="col-11 col-md-8 col-lg-3 shadow-ss-1-btn rounded-3 bg-grey2 py-2 px-3"> Mahbub Hassan </div>
          <div className="col-11 col-md-8 col-lg-3 shadow-ss-1-btn rounded-3 bg-grey2 py-2 px-3"> Treasurer </div>
          <a href="mailto:treasurer@huntsvilleislamiccenter.org" className="col-11 col-md-8 col-lg shadow-ss-1-btn rounded-3 bg-grey py-2 px-3 d-block text-dark text-decoration-none">
            treasurer@huntsvilleislamiccenter.org
          </a>
        </div>

        <div className="fs-ss-18 fw-500 my-3 row justify-content-center gap-3 pb-4 pb-lg-0">
          <div className="col-11 col-md-8 col-lg-3 shadow-ss-1-btn rounded-3 bg-grey2 py-2 px-3"> Misha Siddiqui </div>
          <div className="col-11 col-md-8 col-lg-3 shadow-ss-1-btn rounded-3 bg-grey2 py-2 px-3"> General Secretary </div>
          <a href="mailto:secretary@huntsvilleislamiccenter.org" className="col-11 col-md-8 col-lg shadow-ss-1-btn rounded-3 bg-grey py-2 px-3 d-block text-dark text-decoration-none">
            secretary@huntsvilleislamiccenter.org
          </a>
        </div>

        <div className="fs-ss-18 fw-500 my-3 row justify-content-center gap-3 pb-4 pb-lg-0">
          <div className="col-11 col-md-8 col-lg-3 shadow-ss-1-btn rounded-3 bg-grey2 py-2 px-3"> Muhammad Aly </div>
          <div className="col-11 col-md-8 col-lg-3 shadow-ss-1-btn rounded-3 bg-grey2 py-2 px-3"> Religious Affairs Secretary </div>
          <a href="mailto:religious@huntsvilleislamiccenter.org" className="col-11 col-md-8 col-lg shadow-ss-1-btn rounded-3 bg-grey py-2 px-3 d-block text-dark text-decoration-none">
            religious@huntsvilleislamiccenter.org
          </a>
        </div>

        <div className="fs-ss-18 fw-500 my-3 row justify-content-center gap-3 pb-4 pb-lg-0">
          <div className="col-11 col-md-8 col-lg-3 shadow-ss-1-btn rounded-3 bg-grey2 py-2 px-3"> Anas Alrobiai </div>
          <div className="col-11 col-md-8 col-lg-3 shadow-ss-1-btn rounded-3 bg-grey2 py-2 px-3"> Masjid Affairs Secretary </div>
          <a href="mailto:masjidaffairs@huntsvilleislamiccenter.org" className="col-11 col-md-8 col-lg shadow-ss-1-btn rounded-3 bg-grey py-2 px-3 d-block text-dark text-decoration-none">
            masjidaffairs@huntsvilleislamiccenter.org
          </a>
        </div>

        <div className="fs-ss-18 fw-500 my-3 row justify-content-center gap-3 pb-4 pb-lg-0">
          <div className="col-11 col-md-8 col-lg-3 shadow-ss-1-btn rounded-3 bg-grey2 py-2 px-3"> Idris Leslie </div>
          <div className="col-11 col-md-8 col-lg-3 shadow-ss-1-btn rounded-3 bg-grey2 py-2 px-3"> Dawah Secretary </div>
          <a href="mailto:dawa@huntsvilleislamiccenter.org" className="col-11 col-md-8 col-lg shadow-ss-1-btn rounded-3 bg-grey py-2 px-3 d-block text-dark text-decoration-none">
            dawa@huntsvilleislamiccenter.org
          </a>
        </div>

        <div className="fs-ss-18 fw-500 my-3 row justify-content-center gap-3 pb-4 pb-lg-0">
          <div className="col-11 col-md-8 col-lg-3 shadow-ss-1-btn rounded-3 bg-grey2 py-2 px-3"> Hibah Setayesh </div>
          <div className="col-11 col-md-8 col-lg-3 shadow-ss-1-btn rounded-3 bg-grey2 py-2 px-3"> Social Affairs Secretary </div>
          <a href="mailto:social@huntsvilleislamiccenter.org" className="col-11 col-md-8 col-lg shadow-ss-1-btn rounded-3 bg-grey py-2 px-3 d-block text-dark text-decoration-none">
            social@huntsvilleislamiccenter.org
          </a>
        </div>

        <div className="fs-ss-18 fw-500 my-3 row justify-content-center gap-3 pb-4 pb-lg-0">
          <div className="col-11 col-md-8 col-lg-3 shadow-ss-1-btn rounded-3 bg-grey2 py-2 px-3"> Waseem Quadri </div>
          <div className="col-11 col-md-8 col-lg-3 shadow-ss-1-btn rounded-3 bg-grey2 py-2 px-3"> Education Affairs Secretary </div>
          <a href="mailto:education@huntsvilleislamiccenter.org" className="col-11 col-md-8 col-lg shadow-ss-1-btn rounded-3 bg-grey py-2 px-3 d-block text-dark text-decoration-none">
            education@huntsvilleislamiccenter.org
          </a>
        </div>

        <div className="lh-sm text-center mt-4 mt-md-5  ">
        <div className="fs-ss-40 fw-600">
          Facility Manager
        </div>
        </div>
        <div className="fs-ss-18 fw-500 my-3 row justify-content-center gap-3 pb-4 pb-lg-0">
        <div className="col-11 col-md-8 col-lg-3 bg-grey2 shadow-ss-1-btn rounded-3 py-2 px-3"> Nazish Sayani </div>
        <div className="col-11 col-md-8 col-lg-3 bg-grey2 shadow-ss-1-btn rounded-3 py-2 px-3 fw-500"> Facility Manager </div>
        <a href='mailto:facility.manager@huntsvilleislamiccenter.org' className="d-block col-11 col-md-8 col-lg bg-grey shadow-ss-1-btn rounded-3 py-2 px-3 text-decoration-none text-dark"> facility.manager@huntsvilleislamiccenter.org </a>
    </div>
      </div>
    </div>
  </section>
</TabPanel>




    <TabPanel className="col-12 col-lg-10 mx-auto">
  <section className="container-fluid">
    <div className="">
      <div className="lh-sm">
        <div className="fs-ss-40 fw-600 text-dark">
          Programs & Services
        </div>
      </div>
      <div className="fs-ss-18 fw-400 mt-3">
        <div className="">
          <div className="fs-ss-20 fw-600 text-ss-primary"> Community Prayers </div>
          <ul>
            <li>Daily Prayers: HIC is open for all five daily prayers in congregation.</li>
            <li>Friday (Jumu’ah) Prayer: A weekly sermon and congregational prayer, welcoming the entire community.</li>
            <li>Special Prayers: Tarawih during Ramadan, Eid prayers, and Janazah (funeral) prayers.</li>
          </ul>
          <div className="py-2" />

          <div className="fs-ss-20 fw-600 text-ss-primary"> Educational Programs </div>
          <ul>
            <li>Sunday Islamic School: Offering classes on Quran, Islamic studies, and Arabic for children.</li>
            <li>Islamic Academy of Huntsville: A full-time school for students from preschool through 6th grade.</li>
          </ul>
          <div className="py-2" />

          <div className="fs-ss-20 fw-600 text-ss-primary"> Youth Services </div>
          <ul>
            <li>Youth Center: A space dedicated to nurturing the next generation with activities, workshops, and mentorship.</li>
            <li>Youth Programs: Special events, summer camps, and volunteer opportunities.</li>
          </ul>
          <div className="py-2" />

          <div className="fs-ss-20 fw-600 text-ss-primary"> Family & Social Services </div>
          <ul>
            <li>Marriage Services: Nikah ceremonies conducted in accordance with Islamic principles.</li>
            <li>Funeral Services: Janazah prayers and assistance with burial arrangements.</li>
          </ul>
          <div className="py-2" />

          <div className="fs-ss-20 fw-600 text-ss-primary"> Community Engagement </div>
          <ul>
            <li>Interfaith Outreach: Programs and events to promote understanding and collaboration with other faith groups.</li>
            <li>Volunteer Opportunities: A range of initiatives for those looking to give back to the community.</li>
            <li>Charitable Assistance: Zakat and Sadaqah distribution to help those in need locally and globally.</li>
          </ul>
          <div className="py-2" />

          <div className="fs-ss-20 fw-600 text-ss-primary"> Facility Rentals </div>
          <ul>
            <li>Event Spaces: The HIC facilities are available for private events, including weddings, social gatherings, and meetings.</li>
          </ul>
        </div>
        <div className="pt-3">
          At HIC, we strive to create a welcoming environment that caters to the spiritual, educational, and social needs of our community. For more information or to access any of these services, please <Link to="/contact-us" className='text-ss-primary fw-500'> contact us. </Link>
        </div>
      </div>
    </div>
  </section>
</TabPanel>
    <TabPanel className="col-12 col-lg-10 mx-auto">
    <section className="container-fluid">
      <div className="">
        <div className="lh-sm text-center mb-2">
        <div className="fs-ss-40 fw-600">
          Bylaws & Resolution
        </div>
        <div className="text-ss-secondary fs-ss-22 fw-500">
          Uniting Voices, Enriching Communities
        </div>
        </div>
        <div className="row justify-content-center mt-md-5">

        <div className="col-12 col-md-5 fs-ss-18 fw-500 mt-3 d-block justify-content-center text-center pb-md-3">
        <a href={PDF1} target='_blank' className="d-block text-black text-decoration-none hover-ss-donate rounded-3 px-2 py-3 shadow-ss-1 mb-4 mx-auto"> HIC Bylaws </a>
        </div>
        <div className="col-12 col-md-5 fs-ss-18 fw-500 mt-md-3 d-block justify-content-center text-center">
        <a href={PDF2} target='_blank' className="d-block  text-black text-decoration-none hover-ss-donate rounded-3 px-2 py-3 shadow-ss-1 mx-auto"> HIC Resolution </a>
        </div>

        {/* <div className="fs-ss-18 fw-500 mt-3 row justify-content-center pb-3">
        <div className="col-11 col-md-5 bg-ss-secondary rounded-3 px-2 py-3 me-5 shadow-ss-1"> Ramadan Reservation Form </div>
        <div className="col-11 col-md-5 bg-ss-secondary rounded-3 px-2 py-3 shadow-ss-1"> Ramadan Reservation Form </div>
        </div>

        <div className="fs-ss-18 fw-500 mt-3 row justify-content-center pb-3">
        <div className="col-11 col-md-5 bg-ss-secondary rounded-3 px-2 py-3 me-5 shadow-ss-1"> Ramadan Reservation Form </div>
        <div className="col-11 col-md-5 bg-ss-secondary rounded-3 px-2 py-3 shadow-ss-1"> Ramadan Reservation Form </div>
        </div>

        <div className="fs-ss-18 fw-500 mt-3 row justify-content-center pb-3">
        <div className="col-11 col-md-5 bg-ss-secondary rounded-3 px-2 py-3 me-5 shadow-ss-1"> Ramadan Reservation Form </div>
        <div className="col-11 col-md-5 bg-ss-secondary rounded-3 px-2 py-3 shadow-ss-1"> Ramadan Reservation Form </div>
        </div>
         */}
        </div>
      </div>
    </section>
    </TabPanel>

    </div>
  </Tabs>
    </>
  )
}

export default AboutUsTabs