import React, { useState } from 'react'
import Icon1 from "../../assets/images/services/Icon3.webp";
import Icon2 from "../../assets/images/services/Icon4.webp";
import Icon3 from "../../assets/images/services/Icon1.webp";

const AIAD = () => {

  return (
    <>
    <div className="container-fluid">
    <div className="row justify-content-center align-items-center ps-lg-5 pe-md-5 pe-lg-0">
    <div className="fs-ss-40 fw-600 text-center mb-4 mb-md-5">
        How to Schedule Your Nikah Ceremony?
        </div>
      <div className="col-11 col-md-3 text-md-end mt-4 mt-md-0">
        <img src={Icon1} className="col-3 col-md-8 col-lg-4  pe-4 pe-md-5 mb-2 mb-md-0" alt="" />
      </div>
      <div className="col-11 col-md-9 border-left ps-md-5 mb-7">
      <div className="">
        <div className="lh-sm">
      <div className="fs-ss-30 fw-600 text-ss-primary">
      Obtain Your Marriage License 
      </div>
      </div>
      <div className="py-2" />
      <div className="lh-sm fs-ss-18 fw-400">
        <ul>
        <li className=''> Visit the Marriage Bureau at your local courthouse (e.g., Madison County Courthouse). </li>
        <li className='py-3'> Request and complete your marriage application. </li>
        <li className=''> Inform the Marriage Bureau that your wedding will take place at the Huntsville Islamic Center. </li>
        </ul>
      </div>
      </div>
      </div>

      <div className="col-11 col-md-3 text-md-end mt-4 mt-md-0">
        <img src={Icon3} className="col-3 col-md-8 col-lg-4 pe-4 pe-md-5 mb-2 mb-md-0" alt="" />
      </div>
      <div className="col-11 col-md-9 border-left ps-md-5 mb-7 py-md-4">
      <div className="">
      <div className="fs-ss-30 fw-600 text-ss-primary">
        Schedule an Appointment 
      </div>
      <div className="py-2" />
      <div className="lh-sm fs-ss-18 fw-400">
      Once your application has been processed (allow approximately three business days), contact HIC to schedule your ceremony. <br className="d-none d-md-block" /> Email us at <a className='text-decoration-none text-ss-primary fw-500' href="mailto:board@huntsvilleislamiccenter.org"> board@huntsvilleislamiccenter.org </a>  
      </div>
      </div>
      </div>

      <div className="col-11 col-md-3 text-md-end mt-4 pt-4 pt-md-0 mt-md-0">
        <img src={Icon2} className="col-3 col-md-8 col-lg-4  pe-4 pe-md-5 mb-2 mb-md-0" alt="" />
      </div>
      <div className="col-11 col-md-9 border-left ps-md-5 mb-7">
      <div className="">
      <div className="fs-ss-30 fw-600 text-ss-primary">
        Prepare the Required Documents 
      </div>
      <div className="py-2" />
      <div className="lh-sm fs-ss-18 fw-400">
        <ul>
         <li> The completed marriage application. </li>
         <li className='py-3'> Photo IDs for both the bride and groom (eg., Driver’s License or Passport).  </li>
         <li> Two witnesses. </li>
         <li className='py-3'> The bride’s wali or wakeel (eg., father, older brother, or appointed representative). </li>
         <li> A service fee of $50.00. </li>
        </ul>
      </div>
      </div>
      </div>
  </div>
  </div>
    </>
  )
}

export default AIAD