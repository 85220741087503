import React, { useEffect } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import Icon1 from "../assets/images/weekend-islamic-school/Icon1.webp";
import Icon2 from "../assets/images/weekend-islamic-school/Icon2.webp";
import Icon3 from "../assets/images/weekend-islamic-school/Icon3.webp";
import Icon4 from "../assets/images/weekend-islamic-school/Icon4.webp";
import Icon5 from "../assets/images/weekend-islamic-school/Icon5.webp";
import Icon6 from "../assets/images/weekend-islamic-school/Icon6.webp";
import Icon7 from "../assets/images/weekend-islamic-school/Icon7.webp";
import Icon8 from "../assets/images/weekend-islamic-school/Icon8.webp";
import Banner from "../assets/images/banners/WeekendSchool.webp";
import { Helmet } from "react-helmet";

const WeekendIslamicSchool = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Weekend Islamic School | Huntsville Islamic Center</title>
      </Helmet>
      <section id="website-responsive" className="px-0">
        <section className="container-fluid bg-common-banner" style={{ backgroundImage: `url(${Banner})` }}>
          <Header />
          <div className="fs-banner-title text-white fw-500 text-center py-5">
            <div className="py-md-5 mb-md-5">
              Weekend Islamic School
              <div className="py-3 py-lg-4" />
            </div>
          </div>
        </section>
        <div className="py-4 py-md-5" />
        <section className="container-fluid text-md-center col-11 col-lg-9 mx-auto">
          <div className="">
            <div className="lh-sm">
            <div className="fs-ss-40 fw-600">
            Weekend Islamic School 
            </div>
              </div>
            </div>
            <div className="fs-ss-18 fw-400 mt-3">
                <div className="fs-ss-18 fw-600 pb-2">
                An Ummah where the youth love and live Islam, have a sense of identity, community & fellowship, for the sake of Allah SWT
                </div>
              At the Huntsville Islamic Center (HIC), our Weekend Islamic School is dedicated to fostering a strong connection between our children and their deen. This program provides an enriching environment where Muslim students can come together to learn, grow, and thrive within their faith. 
          </div>
        </section>

        <div className="py-4 py-md-5" />
        <div className="container-fluid">
  <div className="row justify-content-center">
    <div className="fs-ss-40 fw-600 mb-4 mb-md-5 text-center">
      What We Offer 
    </div>

    <div className="col-12 col-lg-5 mb-md-5">
      {/* Faith Connection */}
    <div className="row justify-content-center mb-5 mb-md-0">
      <div className="col-11 col-md-3 text-md-end">
        <img src={Icon1} className="w-icons mb-3 mb-md-0" alt="" />
      </div>
      <div className="col-11 col-md-9">
        <div className="col-md-11">
          <div className="lh-sm">
            <div className="fs-ss-30 fw-600 text-ss-primary">
              Faith Connection:
            </div>
          </div>
          <div className="py-2" />
          <div className="fs-ss-18">
            Our school helps children deepen their understanding of Allah and the basic principles of Islam.
          </div>
        </div>
      </div>
    </div>
    </div>

    <div className="col-12 col-lg-5 mb-md-5">
    {/* Supportive Community */}
    <div className="row justify-content-center mb-5 mb-md-0">
      <div className="col-11 col-md-3 text-md-end">
        <img src={Icon2} className="w-icons mb-3 mb-md-0" alt="" />
      </div>
      <div className="col-11 col-md-9">
        <div className="col-md-11">
          <div className="lh-sm">
            <div className="fs-ss-30 fw-600 text-ss-primary">
              Supportive Community:
            </div>
          </div>
          <div className="py-2" />
          <div className="fs-ss-18">
            We provide a safe space for Muslim students to connect with peers, fostering friendships and the comfort of knowing they are not alone.
          </div>
        </div>
      </div>
    </div>
    </div>

    <div className="col-12 col-lg-5 mb-md-5">
    {/* Practical Learning */}
    <div className="row justify-content-center mb-5 mb-md-0">
      <div className="col-11 col-md-3 text-md-end">
        <img src={Icon3} className="w-icons mb-3 mb-md-0" alt="" />
      </div>
      <div className="col-11 col-md-9">
        <div className="col-md-11">
          <div className="lh-sm">
            <div className="fs-ss-30 fw-600 text-ss-primary">
              Practical Learning:
            </div>
          </div>
          <div className="py-2" />
          <div className="fs-ss-18">
            Students are taught the significance of acts of worship in a way that emphasizes how these practices fit into their routine and contribute to their personal growth.
          </div>
        </div>
      </div>
    </div>
    </div>

    <div className="col-12 col-lg-5 mb-md-5">
    {/* Character Development */}
    <div className="row justify-content-center mb-5 mb-md-0">
      <div className="col-11 col-md-3 text-md-end">
        <img src={Icon8} className="w-icons mb-3 mb-md-0" alt="" />
      </div>
      <div className="col-11 col-md-9">
        <div className="col-md-11">
          <div className="lh-sm">
            <div className="fs-ss-30 fw-600 text-ss-primary">
              Character Development:
            </div>
          </div>
          <div className="py-2" />
          <div className="fs-ss-18">
            Special emphasis is placed on the moral and ethical teachings of Islam, promoting character building, respect, discipline, and kindness.
          </div>
        </div>
      </div>
    </div>
    </div>

    <div className="col-12 col-lg-5 mb-md-5">
    {/* Diverse Teaching Team */}
    <div className="row justify-content-center mb-5 mb-md-0">
      <div className="col-11 col-md-3 text-md-end">
        <img src={Icon4} className="w-icons mb-3 mb-md-0" alt="" />
      </div>
      <div className="col-11 col-md-9">
        <div className="col-md-11">
          <div className="lh-sm">
            <div className="fs-ss-30 fw-600 text-ss-primary">
              Diverse Teaching Team:
            </div>
          </div>
          <div className="py-2" />
          <div className="fs-ss-18">
            Classes are led by a passionate and diverse staff, including college students and young professionals who serve as role models.
          </div>
        </div>
      </div>
    </div>
    </div>

    <div className="col-12 col-lg-5 mb-md-5">
    {/* Morning Assembly */}
    <div className="row justify-content-center mb-5 mb-md-0">
      <div className="col-11 col-md-3 text-md-end">
        <img src={Icon5} className="w-icons mb-3 mb-md-0" alt="" />
      </div>
      <div className="col-11 col-md-9">
        <div className="col-md-11">
          <div className="lh-sm">
            <div className="fs-ss-30 fw-600 text-ss-primary">
              Morning Assembly:
            </div>
          </div>
          <div className="py-2" />
          <div className="fs-ss-18">
            Each day begins with a morning assembly that includes a short Surah recitation, a quick reminder, du’a, hadith, or reflection on recent Islamic events to inspire and focus young minds.
          </div>
        </div>
      </div>
    </div>
    </div>

    <div className="col-12 col-lg-5 mb-md-5">
    {/* Enrichment Classes */}
    <div className="row justify-content-center mb-5 mb-md-0">
      <div className="col-11 col-md-3 text-md-end">
        <img src={Icon7} className="w-icons mb-3 mb-md-0" alt="" />
      </div>
      <div className="col-11 col-md-9">
        <div className="col-md-11">
          <div className="lh-sm">
            <div className="fs-ss-30 fw-600 text-ss-primary">
              Enrichment Classes:
            </div>
          </div>
          <div className="py-2" />
          <div className="fs-ss-18">
            We bring fun and learning together through specialized classes such as Islamic Art & Architecture, Islamic Finance, etc.
          </div>
        </div>
      </div>
    </div>
    </div>

    <div className="col-12 col-lg-5 mb-md-5">
    {/* Age-Specific Curriculum */}
    <div className="row justify-content-center">
      <div className="col-11 col-md-3 text-md-end">
        <img src={Icon6} className="w-icons mb-3 mb-md-0" alt="" />
      </div>
      <div className="col-11 col-md-9">
        <div className="col-md-11">
          <div className="lh-sm">
            <div className="fs-ss-30 fw-600 text-ss-primary">
              Age-Specific Curriculum:
            </div>
          </div>
          <div className="py-2" />
          <div className="fs-ss-18">
            Our customized curriculum caters to different age groups, ensuring age-appropriate and engaging learning experiences.
          </div>
        </div>
      </div>
    </div>
    </div>

  </div>
</div>

        <div className="py-4" />
        <section className="container-fluid text-md-center col-11 col-lg-9 mx-auto">
          <div className="">
            <div className="lh-sm">
            <div className="fs-ss-40 fw-600">
            HIC Sunday School Mission 
            </div>
              </div>
            </div>
            <div className="fs-ss-18 fw-400 mt-3">
              Our mission is to provide a safe and nurturing Islamic environment where children can learn, grow, and thrive. We strive to make Islamic learning fun and engaging, guiding children to live their lives based on the Qur’an and Sunnah. We aim to create a sense of community and belonging by fostering camaraderie and building lasting friendships among students. Additionally, we encourage children to develop a lifelong connection with the Masjid, making it an integral part of their lives. Through these efforts, we hope to inspire a love for Islam that will guide them through every stage of life. 
              <div className="py-2" />
              Join us at the Huntsville Islamic Center for a transformative weekend program designed to nurture the hearts, minds, and faith of the next generation of Muslims. Together, let’s inspire a lifelong love for Islam that empowers them to lead with purpose and values. 
          </div>
        </section>
        <div className="py-4 py-md-5" />
        <Footer />
      </section>
    </>
  );
};

export default WeekendIslamicSchool;
