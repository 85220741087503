import React, { useEffect } from "react";
import Header from "../components/layout/Header";
import MiddleBanner from "../components/common/MiddleBanner";
import Space from "../components/common/Space";
import Footer from "../components/layout/Footer";
import PDF1 from "../assets/images/forms/HIC_MaintenanceForm.pdf";
import PDF2 from "../assets/images/forms/HIC_Property_Rental-Form_HIC.pdf";
import PDF3 from "../assets/images/forms/HIC-Board-Nomination-Form.pdf";
import PDF4 from "../assets/images/forms/HIC-Hardship-Form.pdf";
import PDF5 from "../assets/images/forms/HIC-Proxy-Form.pdf";
import PDF6 from "../assets/images/forms/Non-Disclosure-Agreement-Form.pdf";
import PDF7 from "../assets/images/forms/Ramadan_Reservation_Form_HIC.pdf";
import Banner from "../assets/images/contact-us/Banner.webp";
import BoardMembers from "../components/common/BoardMembers";
import { EnvelopeFill, GeoAltFill, TelephoneFill } from "react-bootstrap-icons";
import InquiryForm from "../components/common/InquiryForm";
import { Helmet } from "react-helmet";

const GeneralForms = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <Helmet>
      <title>
      General Forms | Huntsville Islamic Center
      </title>
    </Helmet>
    <section id="website-responsive" className="px-0">
    <section className="container-fluid bg-common-banner" style={{backgroundImage:`url(${Banner})`}}>
    <Header/>
    <div className="fs-banner-title text-white fw-500 text-center py-5">
      <div className="py-md-5 mb-md-5">
        General Forms
      <div className="py-3 py-lg-4" />
      </div>
    </div>
    </section>
    <div className="py-4 py-md-5" />
    <section className="container-fluid">
      <div className="">
        <div className="lh-sm text-center">
        <div className="fs-ss-40 fw-600">
          General Forms
        </div>
        {/* <div className="text-ss-secondary fs-ss-22 fw-500">
        Empowering Individuals, Enriching Lives
        </div> */}
        </div>
        <div className="container-fluid mt-md-5">

        <div className="fs-ss-18 fw-500 mt-3 row justify-content-center text-center pb-3">
        <a href={PDF1} target="_blank" className="text-decoration-none text-black col-11 col-md-4 bg-ss-light rounded-3 px-2 py-3 me-5 shadow-ss-1"> HIC Maintenance Form </a>
        <a href={PDF2} target="_blank" className="text-decoration-none text-black col-11 col-md-4 bg-ss-light rounded-3 px-2 py-3 shadow-ss-1"> HIC Property Rental Form HIC </a>
        </div>

        <div className="fs-ss-18 fw-500 mt-3 row justify-content-center text-center pb-3">
        <a href={PDF3} target="_blank" className="text-decoration-none text-black col-11 col-md-4 bg-ss-light rounded-3 px-2 py-3 me-5 shadow-ss-1"> HIC Board Nomination Form </a>
        <a href={PDF4} target="_blank" className="text-decoration-none text-black col-11 col-md-4 bg-ss-light rounded-3 px-2 py-3 shadow-ss-1"> HIC Hardship Form </a>
        </div>

        <div className="fs-ss-18 fw-500 mt-3 row justify-content-center text-center pb-3">
        <a href={PDF5} target="_blank" className="text-decoration-none text-black col-11 col-md-4 bg-ss-light rounded-3 px-2 py-3 me-5 shadow-ss-1"> HIC Proxy Form </a>
        <a href={PDF6} target="_blank" className="text-decoration-none text-black col-11 col-md-4 bg-ss-light rounded-3 px-2 py-3 shadow-ss-1"> Non Disclosure Agreement Form </a>
        </div>

        <div className="fs-ss-18 fw-500 mt-3 row justify-content-center text-center pb-3">
        <a href={PDF7} target="_blank" className="text-decoration-none text-black col-11 col-md-4 bg-ss-light rounded-3 px-2 py-3 me-5 shadow-ss-1"> Ramadan Reservation Form HIC </a>

        </div>
        
        </div>
      </div>
    </section>
    <div className="py-4 py-md-5" />
    <Footer/>
    </section>
    </>
  );
}

export default GeneralForms