import React from "react";
import Logo from "../../assets/images/layout/footerlogo.webp"; 
import Facebook from "../../assets/images/layout/Facebook.webp"; 
import Instagram from "../../assets/images/layout/Instagram.webp"; 
import YouTube from "../../assets/images/layout/YouTube.webp"; 
import EmailSubscribeFooter from "../common/EmailSubscribeFooter";
import Space from "../common/Space";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <> 
    <section id="header-links" className="container-fluid bg-footer">
      <div className="py-4" />
    <Space/>
    <Space/>
    <div className="py-5 py-md-0" />
    <EmailSubscribeFooter/>
    <div className="py-4" />
    <section className="container-lg">
      <div className="row justify-content-center justify-content-md-start justify-content-lg-center gy-4 ps-md-3 ps-lg-0">

        <div className="col-11 col-lg-4">
          <img
            src={Logo}
            alt="Logo"
            className="footer-logo"
            />
          <div className="fs-ss-16 fw-400 pt-4 text-white">
          Dedicated to fostering a community rooted in faith, service, and unity, HIC empowers individuals through spiritual growth and collective action. We provide meaningful opportunities for all to connect, learn, and serve. 
          </div>            
        </div>
        
        <div className="col-11 col-md-7 col-lg-5 d-flex justify-content-lg-end">
        <div className="col-8 col-md-6">
          <div className="fs-ss-24 fw-500 text-ss-secondary">
            Services
          </div>
          <div className="fs-ss-16 fw-500 text-white">
          <div className="pt-3"> <Link className="text-decoration-none header-link text-white" to="/marital-ceremonies"> Marriage Ceremonies </Link> </div>
        <div className="pt-3"> <Link className="text-decoration-none header-link text-white" to="/burial-services"> Burial Services </Link> </div>
          <div className="pt-3"> <Link className="text-decoration-none header-link text-white" to="/reverts"> Reverts </Link> </div>
          <div className="pt-3"> <Link className="text-decoration-none header-link text-white" to="/community-outreach"> Community Outreach </Link> </div>
          </div>
        </div>

        <div className="col-5">
          <div className="fs-ss-24 fw-500 text-ss-secondary">
            Quick Links
          </div>
          <div className="fs-ss-16 fw-500 text-white">
          <div className="pt-3"> <Link className="text-decoration-none header-link text-white" to="/resources"> Resources </Link> </div>
          <div className="pt-3"> <Link className="text-decoration-none header-link text-white" to="/events"> Events </Link> </div>
          <div className="pt-3"> <Link className="text-decoration-none header-link text-white" to="/calendar"> Calendar </Link> </div>
          <div className="pt-3"> <Link className="text-decoration-none header-link text-white" to="/privacy-policy"> Privacy Policy </Link> </div>
          </div>
        </div>
        </div>

        <div className="col-11 col-md-3 fw-500">
        <div id="donate" className="fs-ss-24 mt-3 mt-md-0">
        {/* <a className="text-decoration-none text-ss-secondary" to="tel:256-721-1712"> 256-721-1712 </a>
        <div className="py-1" /> */}
        <a target="_blank" href="https://checkout.square.site/merchant/WKRSEAKCJHK6E/checkout/VHNIUNBCZZT77TEG77DKXULW" className="hover-ss-donate text-decoration-none mt-3 mt-md-0 bg-ss-secondary rounded-5 border-0 py-2 px-5 fs-ss-links text-dark fw-500">
          Donate
        </a>
        {/* <a className="text-decoration-none text-ss-secondary" to="mailto:hic@gmail.com"> hic@gmail.com </a>            */}
        <div className="py-2" />
        <div className="fs-ss-24 fw-500 text-ss-secondary">
            Follow Us
          </div>
          <div className="py-2 py-md-0" />

        </div>

          <div className="fs-ss-35">
          <a className="text-decoration-none text-ss-secondary me-2" target="_blank" href="https://www.facebook.com/hiccommunity"> <img src={Facebook} className="col-2 col-md-3 col-lg-2 px-1 hover-social-media" alt="" /> </a> 
          <a className="text-decoration-none text-ss-secondary me-2" target="_blank" href="https://www.instagram.com/hic_community"> <img src={Instagram} className="col-2 col-md-3 col-lg-2 px-1 hover-social-media" alt="" /> </a> 
          <a className="text-decoration-none text-ss-secondary" target="_blank" href="https://www.youtube.com/channel/UCq1M6eOaFxLc_yh22EgcYhA"> <img src={YouTube} className="col-2 col-md-3 col-lg-2 px-1 hover-social-media" alt="" /> </a> 
          </div>
      </div>
        </div>
    </section>
    <div className="py-4" />
    </section>

    <section className="container-fluid py-1 fs-ss-15 bg-white">
    <div className="row justify-content-center">
    <div className="col-md-6 text-center fw-500">
       Copyright © Huntsville Islamic Center 2025 . All Rights Reserved 
      </div>
      </div>
      </section>
      </>
  );
}

export default Footer