import React, { useEffect } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import Icon1 from "../assets/images/community-outreach/Icon1.webp";
import Icon2 from "../assets/images/community-outreach/Icon2.webp";
import Icon3 from "../assets/images/community-outreach/Icon3.webp";
import Icon4 from "../assets/images/community-outreach/Icon4.webp";
import Icon5 from "../assets/images/community-outreach/Icon5.webp";
import Icon6 from "../assets/images/community-outreach/Icon6.webp";
import Icon7 from "../assets/images/community-outreach/Icon7.webp";
import Icon8 from "../assets/images/community-outreach/Icon8.webp";
import Banner from "../assets/images/banners/CommunityOutreach.webp";
import { Helmet } from "react-helmet";

const CommunityOutreach = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Community Outreach | Huntsville Islamic Center</title>
      </Helmet>
      <section id="website-responsive" className="px-0">
        <section className="container-fluid bg-common-banner" style={{ backgroundImage: `url(${Banner})` }}>
          <Header />
          <div className="fs-banner-title text-white fw-500 text-center py-5">
            <div className="py-md-5 mb-md-5">
              Community Outreach
              <div className="py-3 py-lg-4" />
            </div>
          </div>
        </section>
        <div className="py-4 py-md-5" />
        <section className="container-fluid text-md-center col-11 col-lg-10 mx-auto">
          <div className="">
            <div className="lh-sm">
              <div className="fs-ss-40 fw-600">
                Building Bridges with Wisdom and Understanding
              </div>
            </div>
            <div className="fs-ss-18 fw-400 mt-3">
              <div className="fs-ss-18 fw-500">
                "Invite to the way of your Lord with wisdom (hikmah) and beautiful preaching and argue with them in ways that are best."
                <br />
                (Surah An-Nahl 16:125)
              </div>
              <div className="mt-3">
                The Huntsville Islamic Center’s Outreach Program warmly invites you to join us in building a stronger, more connected community. Our mission is to foster open dialogue, deepen understanding, and cultivate mutual respect between the Muslim community and the broader Huntsville community. Through engaging conversations and educational initiatives, we strive to bridge gaps, dispel misconceptions, and create lasting partnerships that celebrate diversity and shared values. Together, let’s embrace the opportunity to grow, learn, and strengthen the bonds that unite us all.
              </div>

              <div className="mt-4">
                To achieve the goals of our Dawah (invitation) and outreach mission, HIC has established a dedicated committee and implemented various measures, including:
              </div>
            </div>
          </div>
        </section>

        <div className="py-4 py-md-5" />
        <div className="container-fluid">
          <div className="row justify-content-center align-items-center gap-md-4 gy-5">

            {/* Campus Outreach */}
            <div className="col-12 col-lg-5">
              <div className="row justify-content-center align-items-center ">
                <div className="col-11 col-md-3 text-md-end">
                  <img src={Icon1} className="w-icons mb-3 mb-md-0" alt="" />
                </div>
                <div className="col-11 col-md-9 ">
                  <div className="col-md-11">
                    <div className="lh-sm">
                      <div className="fs-ss-30 fw-600 text-ss-primary">
                        Campus Outreach
                      </div>
                    </div>
                    <div className="py-1" />
                    <div className="fs-ss-18">
                      Engaging with students and faculty to share knowledge about Islam.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Open House Events */}
            <div className="col-12 col-lg-5">
              <div className="row justify-content-center align-items-center ">
                <div className="col-11 col-md-3 text-md-end">
                  <img src={Icon2} className="w-icons mb-3 mb-md-0" alt="" />
                </div>
                <div className="col-11 col-md-9 ">
                  <div className="col-md-11">
                    <div className="lh-sm">
                      <div className="fs-ss-30 fw-600 text-ss-primary">
                        Open House Events
                      </div>
                    </div>
                    <div className="py-1" />
                    <div className="fs-ss-18">
                      Inviting the community to learn about Islam and meet their Muslim neighbors.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Special Event Booths */}
            <div className="col-12 col-lg-5">
              <div className="row justify-content-center align-items-center ">
                <div className="col-11 col-md-3 text-md-end">
                  <img src={Icon3} className="w-icons mb-3 mb-md-0" alt="" />
                </div>
                <div className="col-11 col-md-9 ">
                  <div className="col-md-11">
                    <div className="lh-sm">
                      <div className="fs-ss-30 fw-600 text-ss-primary">
                        Special Event Booths
                      </div>
                    </div>
                    <div className="py-1" />
                    <div className="fs-ss-18">
                      Participating in local events to answer questions and share resources.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Prison Outreach */}
            <div className="col-12 col-lg-5">
              <div className="row justify-content-center align-items-center ">
                <div className="col-11 col-md-3 text-md-end">
                  <img src={Icon5} className="w-icons mb-3 mb-md-0" alt="" />
                </div>
                <div className="col-11 col-md-9 ">
                  <div className="col-md-11">
                    <div className="lh-sm">
                      <div className="fs-ss-30 fw-600 text-ss-primary">
                        Prison Outreach
                      </div>
                    </div>
                    <div className="py-1" />
                    <div className="fs-ss-18">
                      Offering guidance and support to incarcerated individuals seeking knowledge about Islam.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Volunteering */}
            <div className="col-12 col-lg-5">
              <div className="row justify-content-center align-items-center ">
                <div className="col-11 col-md-3 text-md-end">
                  <img src={Icon6} className="w-icons mb-3 mb-md-0" alt="" />
                </div>
                <div className="col-11 col-md-9 ">
                  <div className="col-md-11">
                    <div className="lh-sm">
                      <div className="fs-ss-30 fw-600 text-ss-primary">
                        Volunteering
                      </div>
                    </div>
                    <div className="py-1" />
                    <div className="fs-ss-18">
                      Supporting local organizations and contributing to community well-being.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Seminars and Classes */}
            <div className="col-12 col-lg-5">
              <div className="row justify-content-center align-items-center ">
                <div className="col-11 col-md-3 text-md-end">
                  <img src={Icon7} className="w-icons mb-3 mb-md-0" alt="" />
                </div>
                <div className="col-11 col-md-9 ">
                  <div className="col-md-11">
                    <div className="lh-sm">
                      <div className="fs-ss-30 fw-600 text-ss-primary">
                        Seminars and Classes
                      </div>
                    </div>
                    <div className="py-1" />
                    <div className="fs-ss-18">
                      Hosting educational sessions such as Islam 101 to provide foundational knowledge.
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Free Outreach Materials */}
            <div className="col-12 col-lg-5">
              <div className="row justify-content-center align-items-center">
                <div className="col-11 col-md-3 text-md-end">
                  <img src={Icon8} className="w-icons mb-3 mb-md-0" alt="" />
                </div>
                <div className="col-11 col-md-9 ">
                  <div className="col-md-11">
                    <div className="lh-sm">
                      <div className="fs-ss-30 fw-600 text-ss-primary">
                        Free Outreach Materials
                      </div>
                    </div>
                    <div className="py-1" />
                    <div className="fs-ss-18">
                      Distributing brochures, books, and other resources to inform and educate.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="col-12 col-lg-5">
              <div className="row justify-content-center align-items-center">
                <div className="col-11 col-md-3 text-md-end">
                  <img src={Icon4} className="w-icons mb-3 mb-md-0" alt="" />
                </div>
                <div className="col-11 col-md-9 ">
                  <div className="col-md-11">
                    <div className="lh-sm">
                      <div className="fs-ss-30 fw-600 text-ss-primary">
                        Interfaith Dialogue
                      </div>
                    </div>
                    <div className="py-1" />
                    <div className="fs-ss-18">
                      Collaborating with diverse faith-based organizations to encourage empathy and dispel misconceptions.
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="py-4 py-md-5" />
          <div className="col-11 col-lg-9 text-md-center mx-auto fs-ss-18 fw-500">
            At the core of our mission is the belief that by working together, we can foster a community of mutual understanding and shared values. We look forward to continuing this important work and welcome everyone to join us in learning and growing together.
          </div>
        </div>
        <div className="py-4 py-md-5" />
        <Footer />
      </section>
    </>
  );
};

export default CommunityOutreach;
