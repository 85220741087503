import React from "react";
import { Accordion, AccordionHeader, AccordionItem } from "react-bootstrap";
import AccordionBody from "react-bootstrap/esm/AccordionBody";

const FAQsSection = (props) => {
  return (
    <>
                      <Accordion className='border-0'>
                      <AccordionItem eventKey="0" className="py-2">
                          <AccordionHeader>
                              <span className='faqs-heading fs-ss-18 text-black'>
                                {props.Title}
                              </span>
                          </AccordionHeader>
                          <AccordionBody>
                              <span className='faqs-ans'>
                              {props.Para}
                              </span>
                          </AccordionBody>
                      </AccordionItem>    
                      </Accordion>
    </>
  )
}

export default FAQsSection

