import React from "react";
import { Link } from "react-router-dom";

const DropdownMenu = (props) => {
  return (
    <> 
    <section className="container-fluid px-4 py-3">
          <div className="fs-ss-16 fw-500 text-dark">
            {props.DropdownItems}
          </div>
    </section>
    </>
  );
}

export default DropdownMenu