import { createContext, useContext, useMemo, useState } from 'react'

export const ModalContext = createContext({
    showModal: null,
    modalContent: null,
    openModal() {},
    closeModal() {},
})

const ModalContextProvider = ({ children }) => {
    const [showModal, setShowModal] = useState(false)
    const [modalContent, setModalContent] = useState(null)

    const openModal = (content) => {
        console.log("open modal ============================== MODAL CONTEXT")
        setShowModal(true)
        setModalContent(content)
    }

    const closeModal = () => {
        setShowModal(false)
        setModalContent(null)
    }

    const values = useMemo(() => ({ showModal, modalContent, openModal, closeModal }), [showModal])

    return <ModalContext.Provider value={values}>{children}</ModalContext.Provider>
}

export const useModal = () => useContext(ModalContext)

export default ModalContextProvider
