import { useEffect, useRef } from 'react'
import { useModal } from './ModalContext'

const Modal = () => {
    const { showModal, modalContent, closeModal } = useModal()

    const modalRef = useRef(null)

    const handleOutsideClick = (event) => {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
            closeModal()
        }
    }

    useEffect(() => {
        if (showModal) {
            document.addEventListener('mousedown', handleOutsideClick)
        }
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick)
        }
    }, [showModal])

    if (!modalContent) {
        return null
    }

    return (
        <div class="position-fixed top-0 start-0 w-100 h-100 z-3">
            <div class="position-absolute-modal">
                <div class="h-auto rounded overflow-hidden w-auto" ref={modalRef}>
                    <div style={{ zIndex: 9 }}>{modalContent}</div>
                </div>
            </div>
        </div>
    )
}

export default Modal
