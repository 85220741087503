import React from 'react'

const ModalContent = (props) => {

  return (
    <>
    <div class="modal modal-lg fade text-dark shadow-ss-1" id={`${props.Id}`} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="p-3 text-end bg-ss-primary">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="row modal-body">
        <div className="col-12">
        <img src={props.Img} className="w-100 shadow-ss-1" alt="" />
        </div>
      </div>
    </div>
  </div>
</div>
    </>
  )
}

export default ModalContent