import React, { useEffect } from "react";
import Header from "../components/layout/Header";
import MiddleBanner from "../components/common/MiddleBanner";
import Space from "../components/common/Space";
import Footer from "../components/layout/Footer";
import Img4 from "../assets/images/contact-us/Img4.webp";
import Img2 from "../assets/images/about-us/Img1.webp";
import Banner from "../assets/images/banners/Event.webp";
import BgImage from "../assets/images/about-us/AboutUsMiddleBanner.webp";
import BoardMembers from "../components/common/BoardMembers";
import AboutUsTabs from "../components/services/AboutUsTabs";
import { Helmet } from "react-helmet";
import UpcomingEvents from "../components/UpcomingEvents/UpcomingEvents";
import EventTabs from "../components/events/EventTabs";

const Events = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <Helmet>
      <title>
        Events | Huntsville Islamic Center
      </title>
    </Helmet>
    <section id="website-responsive" className="px-0">
    <section className="container-fluid bg-common-banner"  style={{backgroundImage:`url(${Banner})`}}>
    <Header/>
    <div className="fs-banner-title text-white fw-500 text-center py-5">
      <div className="py-md-5 mb-md-5">
      Events
      <div className="py-3 py-lg-4" />
      </div>
    </div>
    </section>
    <div className="py-4 py-md-5" />
    <EventTabs/>
    <div className="py-4 py-md-5" />
    <Footer/>
    </section>
    </>
  );
}

export default Events