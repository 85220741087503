import React, { useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Icon1 from "../../assets/images/burial/Icon1.webp";
import Icon2 from "../../assets/images/burial/Icon2.webp";
import Icon3 from "../../assets/images/burial/Icon3.webp";

const BurialTabs = () => {

  return (
    <>
    <Tabs className="row justify-content-evenly gx-0">

    <TabList className="fs-ss-30 fw-600 text-dark col-11 col-lg-3 mb-5 mb-lg-0">
      <Tab className="d-block py-3 cursor px-2 border-right "> 
          <div className=''>
          <img src={Icon3} className="w-icons2 me-3" alt="" />
          Our Services 
          </div>
      </Tab>

      <Tab className="d-block py-3 cursor px-2 border-right "> 
      <div className=''>
      <img src={Icon2} className="w-icons2 me-3" alt="" />
            For Help
          </div>
      </Tab>

      <Tab className="d-block py-3 cursor px-2 border-right "> 
      <div className=''>
      <img src={Icon1} className="w-icons2 me-3" alt="" />
      Cemetery Locations
          </div>
      </Tab>
    </TabList>

    <div className="col-11 col-lg-6">
    <TabPanel className="col-lg-10">
      <div className="">
      <div className="fs-ss-22 mb-3 fw-600 text-dark lh-sm">
        HIC partners with both the Hampton Cove Funeral Home and the Muslim Garden of Alabama, giving you the flexibility to choose the option that best suits your needs. At each location, we offer the following services:
      </div>
      <div className="py-2" />
      <div className="lh-sm fs-ss-18 fw-400">
        <ul>
        <li className='pb-3'> Ghusl (Washing) and Kafan (Shrouding) of the deceased.  </li>
        <li className='pb-3'> Janazah Prayer in accordance with Islamic guidelines.  </li>
        <li className='pb-3'> Dedicated burial plots.</li>
        <li className=''> Easy access to the cemetery for family and visitors.  </li>
        </ul>
      </div>
      </div>
    </TabPanel>

    <TabPanel className="col-md-10">
      <div className="">
        <div className="fs-ss-24 fw-600 mb-3 text-dark">
        Contact HIC  
        </div>
        <div className="fs-ss-18 fw-400">
          Call <a className='text-ss-primary text-decoration-none fw-500' href="tel:2566587553"> 256 658 7553 </a> (Hampton Cove) or <a className='text-ss-primary text-decoration-none fw-500' href="tel:2565208946"> 256 520 8946 </a> (Muslim Garden of Alabama) or email <a className='text-ss-primary text-decoration-none fw-500' href="mailto:board@huntsvilleislamiccenter.org"> board@huntsvilleislamiccenter.org </a> for immediate assistance.
        </div>
      </div>
      <div className="py-3" />
      <div className="">
        <div className="fs-ss-24 fw-600 mb-3 text-dark">
        Coordinate Burial Activities
        </div>
        <div className="fs-ss-18 fw-400">
        Work with the Cemetery Committee to prepare documents, pay burial fees, arrange transportation, and organize the burial. 
        </div>
      </div>
    </TabPanel>

    <TabPanel className="col-lg-10">
      <div className="">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3281.355134306207!2d-86.49109639999999!3d34.6709854!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x886211851f836a9d%3A0x8518b0e944dcf693!2sHampton%20Cove%20Funeral%20Home!5e0!3m2!1sen!2s!4v1735733263159!5m2!1sen!2s" width="100%" height={300} className='shadow-ss-1 rounded-4' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

      <div className="pt-2 text-center fw-500 fs-ss-18">
        Hampton Cove Funeral Home
      </div>

      <div className="py-3" />
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3271.572414203987!2d-86.87707688917926!3d34.917178471454385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8862592f6ca58bcf%3A0x1a1ed24136616e16!2s24824%20Mooresville%20Rd%2C%20Athens%2C%20AL%2035613%2C%20USA!5e0!3m2!1sen!2s!4v1738757523290!5m2!1sen!2s" width="100%" height={300} className='shadow-ss-1 rounded-4' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

      <div className="pt-2 text-center fw-500 fs-ss-18">
        Muslim Garden of Alabama
      </div>

      </div>
    </TabPanel>
    </div>
  </Tabs>
    </>
  )
}

export default BurialTabs