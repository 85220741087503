import React, { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from "react-toastify";
import emailjs from "emailjs-com";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

const InquiryForm = () => {
  const [captchaToken, setCaptchaToken] = useState(null);
  const captchaRef = useRef(); // Reference for reCAPTCHA
  const refform = useRef();

  // State for all input fields
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("+1"); // Default country code
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token); // Update the token on reCAPTCHA completion
  };

  const sendForm = (e) => {
    e.preventDefault();

    // Validate name
    if (!name) {
      toast.error("Please enter your name.", { position: "bottom-left" });
      return;
    }

    // Validate email
    if (!email) {
      toast.error("Please enter your email.", { position: "bottom-left" });
      return;
    }

    // // Validate phone number
    // if (!phone || phone.length < 5) {
    //   toast.error("Please enter a valid phone number.", { position: "bottom-left" });
    //   return;
    // }

    // Validate subject
    if (!subject) {
      toast.error("Please enter a subject.", { position: "bottom-left" });
      return;
    }

    // Validate message
    if (!message) {
      toast.error("Please enter a message.", { position: "bottom-left" });
      return;
    }

    // Validate CAPTCHA
    if (!captchaToken) {
      toast.error(
        <>
          <div className="text-start">
            Google reCAPTCHA verification failed, please try again later.
          </div>
        </>,
        { position: "bottom-left" }
      );
      return;
    }

    // Send the form if all fields are valid and CAPTCHA is completed
    emailjs
      .sendForm(
        "service_hic", // Replace with your EmailJS service ID
        "template_hic", // Replace with your EmailJS template ID
        refform.current, // Use form reference
        "62L2S7468wThNb16U" // Replace with your EmailJS public key
      )
      .then((res) => {
        console.log(res);
        toast.success(
          <>
            <div className="text-start">
              Thank you for your message. We will get back to you shortly!
            </div>
          </>,
          { position: "bottom-left" }
        );
        // Reset reCAPTCHA
        captchaRef.current.reset();
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occurred while submitting the form.");
      });

    // Reset all fields except phone default
    setName("");
    setEmail("");
    setPhone("+1");
    setSubject("");
    setMessage("");
    setCaptchaToken(null); // Reset CAPTCHA token
  };

  return (
    <>
      <ToastContainer />
      <section id="form" className="container-fluid py-5">
      <div className='fs-ss-35 fw-600 lh-sm text-center text-ss-secondary text-hidayatullah pb-4'>
        Write us a message
      </div>
        <div className="col-12 col-md-10 mx-auto">
          <div className="text-center">
            <form
              ref={refform}
              onSubmit={sendForm}
              id="contactForm"
              method="POST"
              action="#"
            >
              <div className="form-group d-md-flex my-3 mt-md-0 mb-md-4">
                <div className="w-50">
                  <input
                    type="text"
                    className="form-control py-2"
                    id="name"
                    name="name"
                    placeholder="Your Name*"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="px-1 px-md-3 my-3 my-md-0" />
                <div className="w-50">
                  <input
                    type="email"
                    className="form-control py-2"
                    id="email"
                    name="email"
                    placeholder="Email*"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group d-md-flex my-3 mt-md-0 mb-md-4">
                <div className="w-50">
                  <PhoneInput
                    country={"us"}
                    id="phone_number_display"
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                    className="form-control py-1"
                    placeholder="" // Removed placeholder
                  />
                  {/* Hidden input to ensure phone number is submitted via emailjs */}
                  <input
                    type="hidden"
                    id="phone_number"
                    name="phone_number"
                    value={phone}
                  />
                </div>
                <div className="px-1 px-md-3 my-3 my-md-0" />
                <div className="w-50">
                  <input
                    type="text"
                    className="form-control py-2"
                    id="subject"
                    name="subject"
                    placeholder="Subject*"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </div>
              </div>
              <div className="form-group">
                <textarea
                  className="col-pri form-control py-2"
                  id="message"
                  name="message"
                  rows="4"
                  placeholder="Write Message*"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
              <div className="text-start mt-4">
                <ReCAPTCHA
                  sitekey="6LeRAMYqAAAAAPkJ56Nnqdgmmsm9QgnISv0tpiMX"
                  onChange={handleCaptchaChange}
                  ref={captchaRef}
                />
                <div className="">
                  <button className="hover-ss-donate rounded-5 py-2 border-0 px-5 mt-3 fs-ss-18 text-dark fw-600">
                    Submit Now
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default InquiryForm;
