import React from "react";
import Header from "../components/layout/Header";
import MiddleBanner from "../components/common/MiddleBanner";
import Space from "../components/common/Space";
import Footer from "../components/layout/Footer";
import Img1 from "../assets/images/youth/Img2.webp";
import Img2 from "../assets/images/youth/Img1.webp";
import Icon1 from "../assets/images/about-us/Icon1.webp";
import Icon2 from "../assets/images/about-us/Icon2.webp";
import Icon3 from "../assets/images/about-us/Icon3.webp";
import Icon4 from "../assets/images/about-us/Icon4.webp";
import Banner from "../assets/images/banners/YouthGroup.webp";
import BgImage from "../assets/images/about-us/AboutUsMiddleBanner.webp";
import BoardMembers from "../components/common/BoardMembers";
import AboutUsTabs from "../components/services/AboutUsTabs";
import { Helmet } from "react-helmet";

const YouthGroup = () => {

  return (
    <>
    <Helmet>
      <title> Youth Group | Huntsville Islamic Center</title>
    </Helmet>
    <section id="website-responsive" className="px-0">
    <section className="container-fluid bg-common-banner"  style={{backgroundImage:`url(${Banner})`}}>
    <Header/>
    <div className="fs-banner-title text-white fw-500 text-center py-5">
      <div className="py-md-5 mb-md-5">
        Youth Group
      <div className="py-3 py-lg-4" />
      </div>
    </div>
    </section>
           <div className="py-4 py-md-5" />
    <section className="container-fluid text-md-center col-11 col-lg-9 mx-auto">
      <div className="">
        <div className="lh-sm">
        <div className="fs-ss-40 fw-600">
        Empowering Young Muslims, Building a Stronger Community 
        </div>
        <div className="text-ss-secondary fs-ss-22 fw-500">
        Fostering Faith and Personal Growth 
        </div>
        </div>
        <div className="fs-ss-18 fw-400 mt-3">
        The HIC Youth Group brings young Muslims in the community together to nurture spiritual growth, cultural understanding, and personal development. Through religious education, community service opportunities, and social events, the group creates a supportive environment for discussing faith, identity, and modern life challenges. 
        </div>
      </div>
    </section>
            <div className="py-4 py-md-5" />
    <section className="container-fluid">
      <div className="row justify-content-center align-items-center gy-4">
        <div className="col-11 col-md-4 d-none d-md-block">
          <img src={Img1} className="w-100 px-lg-5" alt="" />
        </div>
        <div className="col-11 col-md-7 col-lg-5 ps-md-5">
        <div className="fs-ss-40 fw-600">
        Noor Sisters & Ansar Brothers  
        </div>
        <div className="text-ss-secondary fs-ss-22 fw-500">
        Empowering Middle and High School Youth 
        </div>
        <div className="fs-ss-18 fw-400 text-start mt-3">
        These specialized divisions cater to middle school and high school-aged youth, providing a dedicated space for young girls and boys to: 
          <ul className="mt-3">
            <li> Connect with peers. </li>
            <li> Engage in meaningful discussions. </li>
            <li> Develop leadership skills. </li>
          </ul>
          Led by youth leaders, Noor Sisters and Ansar Brothers foster strong bonds, personal growth, and a sense of belonging within the community.
        </div>
        </div>
        <div className="col-8 d-block d-md-none">
          <img src={Img1} className="w-100 px-lg-5" alt="" />
        </div>
      </div>
    </section>
            <div className="py-4 py-md-5" />
    <section className="container-fluid text-md-center col-11 col-lg-9 mx-auto">
      <div className="">
        <div className="lh-sm">
        <div className="fs-ss-40 fw-600">
        Youth Council: Shaping the Future 
        </div>
        </div>
        <div className="fs-ss-18 fw-400 mt-3">
        The Youth Council guides the group’s initiatives with leadership from Noor Sisters and Ansar Brothers. They organize events, plan community outreach, and shape future activities, empowering members to actively participate, collaborate, and make a positive impact on the youth group and the broader community. 
        </div>
      </div>
    </section>
            <div className="py-4 py-md-5" />
    <MiddleBanner
    BgImage={BgImage}
    Title="Together in Faith"
    SubTitle="Together in Service"
    BtnTitle="Become a Member"
    />
           <div className="py-4 py-md-5" />
    <section className="container-fluid">
      <div className="row justify-content-center align-items-center gy-4">
      <div className="col-11 col-md-7 col-lg-5 pe-0 pe-md-5 pe-lg-0">
        <div className="fs-ss-40 fw-600">
        Mini Youth Group  
        </div>
        <div className="text-ss-secondary fs-ss-22 fw-500">
        Fun, Faith, and Friendship for Kids 
        </div>
        <div className="fs-ss-18 fw-400 text-start mt-3">
          Designed for younger children aged 5-8 and 9-12, the Mini Youth Group offers an engaging and playful environment where children can learn about Islam through: 
          <ul className="mt-3">
            <li> Games and crafts. </li>
            <li> Interactive activities. </li>
            <li> Fun, age-appropriate lessons. </li>
          </ul>
          Led by dedicated community members, the Mini Youth Group fosters personal growth, a strong connection to faith, and a sense of belonging, ensuring children grow spiritually while enjoying time with their peers
        </div>
        </div>
        <div className="col-8 col-md-4">
          <img src={Img2} className="w-100 px-lg-5" alt="" />
        </div>
      </div>
    </section>
            <div className="py-4 py-md-5" />
    <section className="container-fluid text-md-center col-11 col-lg-8 mx-auto">
      <div className="">
        <div className="lh-sm">
        <div className="fs-ss-40 fw-600">
        Join the HIC Youth Group 
        </div>
        </div>
        <div className="fs-ss-18 fw-400 mt-3">
        Discover a vibrant, faith-filled community where young Muslims thrive, connect, and lead. Whether through learning, service, or fun activities, there’s a place for everyone in the HIC Youth Group. 
        </div>
      </div>
    </section>
            <div className="py-4 py-md-5" />
    <Footer/>
    </section>
    </>
  );
}

export default YouthGroup