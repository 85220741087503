import React, { Component } from "react";
import Slider from "react-slick";
import Gallery1 from "../../assets/images/home/Gallery/1.webp";
import Gallery2 from "../../assets/images/home/Gallery/2.webp";
import Gallery3 from "../../assets/images/home/Gallery/3.webp";
import Gallery4 from "../../assets/images/home/Gallery/4.webp";
import Gallery5 from "../../assets/images/home/Gallery/5.webp";
import Gallery6 from "../../assets/images/home/Gallery/6.webp";
import Gallery7 from "../../assets/images/home/Gallery/7.webp";
import Gallery8 from "../../assets/images/home/Gallery/8.webp";

export default class Responsive extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 0,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div className="col-11 col-md-10 mx-auto px-md-5">
        <Slider {...settings}>
        <div>
    <img src={Gallery6} className="w-90 mx-auto px-md-2 px-3" alt="..."/>
    </div>
    <div>
    <img src={Gallery7} className="w-90 mx-auto px-md-2 px-3" alt="..."/>
    </div>
    <div>
    <img src={Gallery8} className="w-90 mx-auto px-md-2 px-3" alt="..."/>
    </div>
    <div>
    <img src={Gallery1} className="w-90 mx-auto px-md-2 px-3" alt="..."/>
    </div>
    <div>
    <img src={Gallery2} className="w-90 mx-auto px-md-2 px-3" alt="..."/>
    </div>
    <div>
    <img src={Gallery3} className="w-90 mx-auto px-md-2 px-3" alt="..."/>
    </div>
    <div>
    <img src={Gallery4} className="w-90 mx-auto px-md-2 px-3" alt="..."/>
    </div>
    <div>
    <img src={Gallery5} className="w-90 mx-auto px-md-2 px-3" alt="..."/>
    </div>
        </Slider>
      </div>
    );
  }
}