import React from "react";
import {Link, useLocation} from "react-router-dom";

const NavBarLink = (props) => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = useLocation;
  return (
    <>
    <li className="nav-item me-md-2">
    <Link className={splitLocation[1] === `${props.Link_To}` ? `text-ss-primary nav-link hover-ss-white fw-500 ${props.Border}` : `text-ss-primary nav-link hover-ss-white fw-500 ${props.Border}`} to={`/${props.Link_To}`}> {props.Name} </Link>
    </li>
    </>
  )
}

export default NavBarLink