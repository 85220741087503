import React, { useEffect } from "react";
import Header from "../components/layout/Header";
import MiddleBanner from "../components/common/MiddleBanner";
import Space from "../components/common/Space";
import Footer from "../components/layout/Footer";
import Img1 from "../assets/images/contact-us/Img1.webp";
import Img2 from "../assets/images/contact-us/Img2.webp";
import Img3 from "../assets/images/contact-us/Img3.webp";
import Img4 from "../assets/images/contact-us/Img4.webp";
import Icon1 from "../assets/images/contact-us/Icon1.webp";
import Icon2 from "../assets/images/contact-us/Icon2.webp";
import Icon3 from "../assets/images/contact-us/Icon3.webp";
import Banner from "../assets/images/contact-us/Banner.webp";
import BoardMembers from "../components/common/BoardMembers";
import { EnvelopeFill, GeoAltFill, TelephoneFill } from "react-bootstrap-icons";
import InquiryForm from "../components/common/InquiryForm";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <Helmet>
      <title>
      Contact Us | Huntsville Islamic Center
      </title>
    </Helmet>
    <section id="website-responsive" className="px-0">
    <section className="container-fluid bg-common-banner" style={{backgroundImage:`url(${Banner})`}}>
    <Header/>
    <div className="fs-banner-title text-white fw-500 text-center py-5">
      <div className="py-md-5 mb-md-5">
      Contact Us
      <div className="py-3 py-lg-4" />
      </div>
    </div>
    </section>
    <div className="py-4 py-md-5" />
    <section className="container-fluid">
      <div className="row justify-content-center px-lg-5 gy-5">
      {/* <div className="col-custom-contact text-center text-ss-primary">
          <TelephoneFill className="fs-ss-68"/>
          <div className="fs-ss-22 mt-2 text-dark fw-600">
            Phone
          </div>
          <a className="fs-ss-20 fw-600 text-dark text-decoration-none" href="tel:256-721-1712">
            256-721-1712
          </a>
        </div> */}

        <div className="col-11 col-md-5 col-lg-4 text-center text-ss-primary border-left-right">
          <GeoAltFill className="fs-ss-68"/>
          <div className="fs-ss-22 mt-2 text-dark fw-600">
            Address
          </div>
          <a className="fs-ss-20 fw-600 text-dark text-decoration-none lh-sm" href="https://maps.app.goo.gl/55bheSo55Hp2h8x2A" target="_blank">
            1645 Sparkman Drive Huntsville, AL 35816
          </a>
        </div>

        <div className="col-11 col-md-5 col-lg-4 text-center text-ss-primary">
          <EnvelopeFill className="fs-ss-68"/>
          <div className="fs-ss-22 mt-2 text-dark fw-600">
            Email
          </div>
          <a className="fs-ss-20 fw-600 text-dark text-decoration-none" href="mailto:board@huntsvilleislamiccenter.org">
            board@huntsvilleislamiccenter.org
          </a>
        </div>
      </div>
    </section>
    <div className="py-4 py-md-5" />
    <section className="container-fluid">
      <div className="row justify-content-evenly px-lg-5 mx-md-3 mx-lg-5 gap-3 gap-3">
        <a href="https://forms.gle/qfEWtWdHjTBNf5D7A" target='_blank' className="text-decoration-none d-block col-custom-contact text-center text-white bg-common-banner py-4 shadow-ss-1" style={{backgroundImage:`url(${Img1})`}}>
          <div className="fs-ss-35 fw-500 py-md-5 my-5 lh-sm" >
          Schedule <br className="d-none d-md-block" /> a Visit
          </div>
        </a>

        <a href="https://forms.gle/broFjS7cwYRk89Sd6" target='_blank' className="text-decoration-none d-block col-custom-contact text-center text-white bg-common-banner py-4 shadow-ss-1" style={{backgroundImage:`url(${Img2})`}}>
          <div className="fs-ss-35 fw-500 py-md-5 my-5 lh-sm lh-sm" >
            Become <br className="d-none d-md-block" /> a Member
          </div>
        </a>

        <a href="https://forms.gle/rhS1j45artxrxUQG9" target='_blank' className="text-decoration-none d-block col-custom-contact text-center text-white bg-common-banner py-4 shadow-ss-1" style={{backgroundImage:`url(${Img3})`}}>
          <div className="fs-ss-35 fw-500 py-md-5 my-5 lh-sm lh-sm" >
          Volunteer <br className="d-none d-md-block" /> with Us
          </div>
        </a>
      </div>
    </section>
    <div className="py-4 py-md-5" />
    <section className="container-fluid">
    <div className="lh-sm text-center pb-3 pb-md-5">
        <div className="fs-ss-40 fw-600">
          Follow Us
        </div>
        <div className="text-ss-secondary fs-ss-22 fw-500">
        Stay Connected, Stay Inspired!
        </div>
        </div>
      <div className="row justify-content-center text-center">
        <a href="https://www.facebook.com/hiccommunity/" target="_blank" className="col-2 col-lg-1">
          <img src={Icon1} className="w-100 px-md-3 px-lg-0" alt="" />
        </a>
        <a href="https://www.youtube.com/channel/UCq1M6eOaFxLc_yh22EgcYhA" target="_blank" className="col-2 col-lg-1 mx-lg-5">
        <img src={Icon2} className="w-100 px-md-3 px-lg-0" alt="" />
        </a>
        <a href="https://www.instagram.com/hic_community" target="_blank" className="col-2 col-lg-1">
        <img src={Icon3} className="w-100 px-md-3 px-lg-0" alt="" />
        </a>
      </div>
    </section>
    <div className="py-4 py-md-5" />
    <section className="container-fluid">
      <div className="row justify-content-center gy-4">
        <div className="col-11 col-lg-6 px-0 d-none d-lg-block">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3278.514795192117!2d-86.65124658918704!3d34.74262278085008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88626beee4aecde7%3A0xb7695647cfc648ed!2sHuntsville%20Islamic%20Center!5e0!3m2!1sen!2s!4v1730976376000!5m2!1sen!2s" height={580} className="w-100 border-0 shadow-ss-1" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"/>
        </div>
      <div className="col-11 col-lg-6 bg-ss-primary">
        <InquiryForm/>
        </div>
        <div className="col-11 col-lg-6 px-0 d-block d-lg-none">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3278.514795192117!2d-86.65124658918704!3d34.74262278085008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88626beee4aecde7%3A0xb7695647cfc648ed!2sHuntsville%20Islamic%20Center!5e0!3m2!1sen!2s!4v1730976376000!5m2!1sen!2s" height={250} className="w-100 border-0 shadow-ss-1" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"/>
        </div>
      </div>
    </section>
    <div className="py-4 py-md-5" />
    <Footer/>
    </section>
    </>
  );
}

export default ContactUs