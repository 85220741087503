import React, { useEffect } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import Img1 from "../assets/images/zakat/Img1.webp";
import Banner from "../assets/images/banners/Zakat.webp";
import { Helmet } from "react-helmet";

const ZakatAndSadaqahDistribution = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Zakat and Sadaqah Distribution | Huntsville Islamic Center
        </title>
      </Helmet>
      <section id="website-responsive" className="px-0">
        <section className="container-fluid bg-common-banner" style={{ backgroundImage: `url(${Banner})` }}>
          <Header />
          <div className="fs-banner-title text-white fw-500 text-center py-5">
            <div className="py-md-5 mb-md-5">
              Zakat and Sadaqah
              <div className="py-3 py-lg-4" />
            </div>
          </div>
        </section>
        <div className="py-4 py-md-5" />
        <section className="container-fluid text-md-center col-11 col-lg-10 mx-auto">
          <div className="">
            <div className="lh-sm">
              <div className="fs-ss-40 fw-600">
                Supporting Our Community in Need
              </div>
            </div>
            <div className="fs-ss-18 fw-400 mt-3">
              The Huntsville Islamic Center is dedicated to supporting members of our community in need through our Zakat and Sadaqah Distribution Services. We collect and distribute funds to assist individuals and families facing financial challenges, helping them meet their immediate needs in a timely and reasonable manner. 
              Our mission is to ensure that the funds entrusted to us are distributed responsibly and compassionately, in accordance with Islamic principles, to those who need it most.
            </div>
          </div>
        </section>
        <div className="py-4 py-md-5" />
        <section className="container-fluid px-md-5">
          <div className="row justify-content-evenly align-items-center gy-4 px-lg-4">
            <div className="col-11 col-md-7 col-lg-6">
              <div className="lh-sm">
                <div className="fs-ss-40 fw-600">
                  How We Help
                </div>
              </div>
              <div className="fs-ss-18 fw-400 mt-3">
                The Zakat and Sadaqah Distribution Service at HIC is committed to supporting our community members in difficult circumstances. Our goal is to address immediate needs such as food, shelter, medical expenses, and other essential living costs. By providing timely assistance, we aim to alleviate financial burdens and empower those in need to work toward stability. 
                <div className="py-2" />
                HIC ensures that these contributions are distributed with transparency, compassion, and respect for the dignity of recipients. Through this service, HIC is honored to be a trusted channel for fulfilling this sacred obligation, connecting donors with those in need and strengthening the bonds of our community.

              </div>
            </div>
            <div className="col-8 col-md-4">
              <img src={Img1} className="w-100 px-lg-5" alt="" />
            </div>
          </div>
        </section>
    <div className="py-4 py-md-5" />
    <section className="container-fluid text-md-center col-11 col-lg-9 mx-auto bg-join-team py-5 shadow-ss-1 rounded-200">
          <div className="">
            <div className="lh-sm">
            <div className="fs-ss-48 fw-600 text-white text-center">
              Your Gift Today is <br /> Someone's Miracle Tomorrow
            </div>
              </div>
            </div>
          <div className="row justify-content-center gap-md-4 text-center mt-5">
          <div className="col-11 col-md-6 col-lg-4 ">
              <a href="https://square.link/u/zMUuD6Ws" target='_blank' className="d-block fs-ss-24 fw-500 hover-ss-donate rounded-3 py-2 mb-4 mb-md-0 mb-lg-4 text-black text-decoration-none">
                Donate Sadaqah
              </a>
          </div>
          <div className="col-11 col-md-6 col-lg-4">
              <a href="https://square.link/u/DXjTOUMP" target='_blank' className="d-block fs-ss-24 fw-500 hover-ss-donate rounded-3 py-2 mb-4 text-black text-decoration-none">
                Donate Zakat
              </a>
          </div>
          </div>
        </section>
        <div className="py-4 py-md-5" />
        <Footer />
      </section>
    </>
  );
};

export default ZakatAndSadaqahDistribution;
