import React from "react";
import {Link, useLocation} from "react-router-dom";

const NavBarLink3 = (props) => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = useLocation;
  return (
    <>
    <li className="nav-item hover-ss-white right-menu d-block d-lg-none">
          <div className="btn-group dropdown">
          <span className="nav-link hover-ss-white fw-500"> {props.Name} </span>

            <Link to="" className="btn px-4 dropdown-toggle dropdown-toggle-split border-0 text-white" data-bs-toggle="dropdown-menu" aria-expanded="false">
              <span className="visually-hidden">Toggle Dropdown</span>
              </Link>
          </div>
          <ul className="dropdown-menu w-content p-0 bg-ss-primary-op">
            {props.DropdownMenu}
          </ul>
    </li>
    
    <li className="nav-item hover-ss-white right-menu d-none d-lg-block me-lg-2">
          <span className="nav-link dropdown-toggle hover-ss-white fw-500" > {props.Name} </span>
          <ul className="dropdown-menu w-content p-0">
            {props.DropdownMenu}
          </ul>
    </li>
    </>
  )
}

export default NavBarLink3