import React, {useEffect} from 'react'
import Header from '../components/layout/Header'
import {Link} from 'react-router-dom'
import Footer from '../components/layout/Footer'
import { Helmet } from "react-helmet";

const Error404 = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    
      return (
        <>
        <Helmet>
          <title>
            Page Not Found (404) | Huntsville Islamic Center
          </title>
        </Helmet>
        <div className="px-0 bg"> 
        <div className="bg-ss-primary pb-2">
        <Header/>
        </div>
            <div className='' id="website-responsive">
                {/* <BannerCategory /> */}
                <section className='container'>
                    <div className='col-8 mx-auto my-5 text-center bg-ss-primary py-5'>
                        <h4 className='text-white mb-4'>This page doesn't seem to exist.</h4>
                        <div className='text-white'>It looks like the link pointing here was faulty. Visit home page!</div>
                        <div className='mt-5'>
                            <Link to='/' className='text-decoration-none bg-ss-secondary rounded-5 text-black py-2 px-5 fw-500'>
                                Return Home
                            </Link>
                        </div>
                    </div>
                </section>
            </div>
        <Footer/>
        </div>
        </>
    )
}

export default Error404