import React from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import Img1 from "../assets/images/ramadan/Img1.webp";
import Banner from "../assets/images/ramadan/BgBanner.webp";
import RamadanCalendar from "../assets/images/ramadan/HIC_Ramdan_Calendar.pdf";
import BgImage from "../assets/images/home/MiddleBanner.webp";
import { Helmet } from "react-helmet";
import Slider from "react-slick";

// Import slick-carousel CSS
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import RamadanEvents from "../components/UpcomingEvents/RamadanEvents";

const Ramadan = () => {

  const sliderSettings = {
    dots: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    infinite: true,
    speed: 500,
    fade: true,
  };

  return (
    <>
      <Helmet>
        <title> Ramadan | Huntsville Islamic Center</title>
      </Helmet>
      <section id="website-responsive" className="px-0 bg-ramadan-1">
        <section className="container-fluid bg-common-banner" style={{backgroundImage:`url(${Banner})`}}>
          <Header/>
          <div className="py-3 py-lg-4" />
          <div className="">
          <div className="fs-banner-title text-white fw-500 text-center py-5">
            Ramadan 2025
              <div className="fs-ss-24 ls-0">
                Together in Faith, Together in Worship
              </div>
            </div>
          </div>
          <div className="py-3 py-lg-4" />
        </section>
        <div id="ramadan" className="text-center fs-ss-24 bg-ramadan-2 px-4 px-md-0 pb-5 pb-md-0">
        <div className="py-4 py-lg-5" />
        <div className="py-md-3" />
        <a href={RamadanCalendar} target='_blank' className=" d-block d-md-inline text-decoration-none rounded-5 border-0 btn-padding-middle hover-ss-donate text-dark fw-500 shadow-ss-1">
        Ramadan Calendar 2025
        </a>
        <span className="mx-0 mx-md-2 mx-lg-5" />
        <a href="https://docs.google.com/forms/d/e/1FAIpQLScrC4_S9Nrm7WhBi6GM5d24YOEEsHcngpjqIDgmu61b0oB3lg/viewform?pli=1" target='_blank' className=" d-block d-md-inline mt-4 text-decoration-none rounded-5 border-0 btn-padding-middle hover-ss-donate text-dark fw-500 shadow-ss-1">
        <span className="px-2" /> Iftar Sponsorship <span className="px-2" />
        </a>
        </div>
        <section className="container-fluid">
          <div className="row justify-content-center align-items-center gy-4">
            <div className="col-11 col-md-4 d-none d-md-block">
              <img src={Img1} className="w-100 px-xl-4" alt="" />
            </div>
            <div className="col-11 col-md-7 col-lg-5 ps-md-5">
              <div className="fs-ss-40 fw-600 lh-sm">
                Ramadan at Our Masjid
              </div>
              <div className="fs-ss-18 fw-400 text-start mt-3">
              The Huntsville Islamic Center warmly invites you to join us for a variety of special activities and events during this blessed month of Ramadan. The masjid is open every day for iftar (fast-breaking meal). Weekday iftars are specifically catered to new Muslims, students, travelers, etc. Our weekend iftars are open to everyone and we encourage you to come and break your fast as a community.  
              <div className="py-2" />
              In addition to the regular schedule of the five daily prayers, we also offer nightly Taraweeh prayers with a short khatira (reminder) and special qiyam nights. For more information on prayer timings, please refer to the <a href={RamadanCalendar} className="text-ss-primary fw-700 text-decoration-none" target="_blank"> Ramadan Calendar. </a>
              <div className="py-2" />
              We also host special Quran Memorization Contests for both youth and adults. Additionally, during the blessed last 10 nights of Ramadan, we offer the opportunity for itikaf. For more details about the contests or to reserve your spot for itikaf, please reach out to us at <a href="mailto:religious@huntsvilleislamiccenter.org" className="text-ss-primary fw-700 text-decoration-none" target="_blank">
              religious@huntsvilleislamiccenter.org. </a>
              </div>
            </div>
            <div className="col-10 d-block d-md-none">
              <img src={Img1} className="w-100 px-xl-5 px-lg-3" alt="" />
            </div>
          </div>
        </section>
        <div className="py-4 py-md-5" />
        <RamadanEvents/>
        <div className="py-4 py-md-5" />
        <section id="middle-banner" className="container-fluid text-white bg-join-team py-lg-5 py-4 text-center text-lg-start" style={{backgroundImage:`url(${BgImage})`}}>
          <div className="row justify-content-center">
            <div className="col-md-10 text-center">
              <div className="py-4 my-lg-2">
                  <div>
                    <div className="text-ss-secondary fs-ss-35">
                    يَا أَيُّهَا الَّذِينَ آمَنُوا كُتِبَ عَلَيْكُمُ الصِّيَامُ كَمَا كُتِبَ عَلَى الَّذِينَ مِنْ قَبْلِكُمْ لَعَلَّكُمْ تَتَّقُونَ
                    </div>
                    <div className="fs-ss-22 fw-500 mt-4">
                    "O believers! Fasting is prescribed for you - as it was for those before you - <br /> so perhaps you will become mindful of Allah."
                    </div>
                    <div className="mt-4 fs-ss-22 text-ss-secondary fw-700">
                      Surah Baqarah 183
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </section>
        <div className="py-4 py-md-5" />
        <div className="container faq-section" id="ramadan-id">
  <div className="accordion" id="faqAccordion">
    
    <div className="accordion-item py-2 mb-4">
      <h2 className="accordion-header" id="headingOne">
        <button className="accordion-button fs-ss-24  fw-500 fw-500" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
          How can I sponsor a weekend Iftar for the community?
        </button>
      </h2>
      <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#faqAccordion">
        <div className="accordion-body fs-ss-18 fw-500">
          You can sponsor a weekend Iftar by reaching out to Sr. Hibah at <a className="fw-700 text-decoration-none text-dark" href="tel:2566527615">256-652-7615</a>.
        </div>
      </div>
    </div>

    <div className="accordion-item py-2 mb-4">
      <h2 className="accordion-header" id="headingTwo">
        <button className="accordion-button fs-ss-24  fw-500" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
          What happens if a Sunday remains unsponsored?
        </button>
      </h2>
      <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#faqAccordion">
        <div className="accordion-body fs-ss-18 fw-500">
          If an open Sunday is not sponsored, it will be treated as a weekday Iftar instead.
        </div>
      </div>
    </div>

    <div className="accordion-item py-2 mb-4">
      <h2 className="accordion-header" id="headingThree">
        <button className="accordion-button fs-ss-24  fw-500" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
        Where do I find Suhoor/Iftar timings?
        </button>
      </h2>
      <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#faqAccordion">
        <div className="accordion-body fs-ss-18 fw-500">
        You can find the Suhoor and Iftar timings by checking our <a href={RamadanCalendar} className="text-ss-primary fw-700 text-decoration-none" target="_blank"> Ramadan Calendar. </a>
        </div>
      </div>
    </div>

    <div className="accordion-item py-2 mb-4">
      <h2 className="accordion-header" id="headingFour">
        <button className="accordion-button fs-ss-24  fw-500" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
        How do I know about special events/activities this Ramadan?
        </button>
      </h2>
      <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#faqAccordion">
        <div className="accordion-body fs-ss-18 fw-500">
        To stay informed about special events and activities during Ramadan, please check our <Link to="/calendar" className="text-ss-primary fw-700 text-decoration-none"> Google Calendar. </Link>
        </div>
      </div>
    </div>

    <div className="accordion-item py-2 mb-4">
      <h2 className="accordion-header" id="headingFive">
        <button className="accordion-button fs-ss-24  fw-500" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
        How do I register for the Youth Quran Competition?
        </button>
      </h2>
      <div id="collapseFive" className="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#faqAccordion">
        <div className="accordion-body fs-ss-18 fw-500">
        To register for the Youth Quran Competition, simply fill out the <a href="https://docs.google.com/forms/d/e/1FAIpQLSd_eAuQT24JwzTF9hBYBbZMkgtEnUcTF-v6cj8u9li9ZBa9QQ/viewform" className="text-ss-primary fw-700 text-decoration-none" target="_blank">
          Youth Quran Competition Form. </a>
        </div>
      </div>
    </div>

    <div className="accordion-item py-2 mb-4">
      <h2 className="accordion-header" id="headingFive2">
        <button className="accordion-button fs-ss-24  fw-500" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive2" aria-expanded="true" aria-controls="collapseFive2">
        How do I register for the Adult Quran Competition?
        </button>
      </h2>
      <div id="collapseFive2" className="accordion-collapse collapse" aria-labelledby="headingFive2" data-bs-parent="#faqAccordion">
        <div className="accordion-body fs-ss-18 fw-500">
        To participate in the Adult Quran Competition, no registration is required. Simply join us in the musalla area on March 22 after Dhuhr prayer. For the list of surahs or additional details, please email us at <a href="mailto:religious@huntsvilleislamiccenter.org" className="text-ss-primary fw-700 text-decoration-none" target="_blank">
        religious@huntsvilleislamiccenter.org. </a>
        </div>
      </div>
    </div>

    <div className="accordion-item py-2">
      <h2 className="accordion-header" id="headingSix">
        <button className="accordion-button fs-ss-24  fw-500" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
        Any information on Eid Prayers? 
        </button>
      </h2>
      <div id="collapseSix" className="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#faqAccordion">
        <div className="accordion-body fs-ss-18 fw-500">
          Join us for the Eid 2025 Prayer at the Space and Rocket Center - Saturn V Hall on Sunday, March 30th.<br /><br />
          Eid Prayer Schedule:<br /><br />
          7:00 AM – Guests Begin Arriving<br />
          7:30 AM – Takbeerat Begins<br />
          8:00 AM – Eid Khutbah & Prayer
        </div>
      </div>
    </div>

  </div>
</div>

        <div className="py-4 py-md-5" />
        <Footer/>
      </section>
    </>
  );
}

export default Ramadan;
