import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter} from 'react-router-dom'
import Modal from './components/Modal';
import ModalContextProvider from './components/ModalContext'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <ModalContextProvider>

      <App/>
    </ModalContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);