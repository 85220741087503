import React, { useEffect, useState } from "react";
import Slider from 'react-slick';
import eventsData from "../events/events.json";  // Import events data from events.json
import ModalContent from './ModalContent';
import ModalToggler from './ModalToggler';

const UpcomingEvents = () => {
  const [upcomingEvents, setUpcomingEvents] = useState([]);

  // Helper function to format the date without the weekday
  const formatDate = (dateString) => {
    const localDate = new Date(dateString + "T00:00:00"); // Adjust to local time
    const options = { year: 'numeric', month: 'long', day: 'numeric' }; // Only show month, day, year
    return localDate.toLocaleDateString('en-US', options); // Adjust locale as needed
  };

  // Helper function to filter and sort upcoming events
  const filterUpcomingEvents = () => {
    const currentDate = new Date();
    // Filter and sort the events based on the date
    const filteredEvents = eventsData
      .filter((event) => new Date(event.date) >= currentDate)
      .sort((a, b) => new Date(a.date) - new Date(b.date));  // Sort in ascending order
    setUpcomingEvents(filteredEvents);
  };

  useEffect(() => {
    filterUpcomingEvents(); // Filter and sort events when the component mounts
  }, []);

  // Settings for the Slick slider
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Always try to show 3 items
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 7000,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: true,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: true,
        }
      }
    ]
  };

  return (
    <section className="container-fluid">
      <div className="pb-4 text-center px-4 px-md-0">
        <div className="fs-ss-40 fw-600">
          Upcoming Events
        </div>
        <div className="text-ss-secondary fs-ss-22 fw-500">
          Join Us in Building a Stronger Community
        </div>
      </div>
      <section className="container-lg">
        <div className="row justify-content-center px-md-5">
          {/* Modal Content for Upcoming Events */}
          {upcomingEvents.length > 0 ? (
            upcomingEvents.map((event) => (
              <ModalContent
                key={event.id}
                Img={event.image}
                Id={event.id}
                Title={event.title}
                Date={formatDate(event.date)} 
                StartTime={`Start Time: ${event.startTime}`}
                EndTime={event.endTime ? `End Time: ${event.endTime}` : ""}
              />
            ))
          ) : (
            <p className="text-center fs-ss-18 fw-500">No upcoming events.</p>
          )}

          {/* Conditionally render the Slider */}
          {upcomingEvents.length >= 3 ? (
            <Slider {...settings}>
              {upcomingEvents.map((event) => (
                <div key={event.id} className="slider-item">
                  <ModalToggler
                    w90="w-90"
                    Img={event.image}
                    Id={event.id}
                    Title={event.title}
                    Date={formatDate(event.date)} 
                    StartTime={`Start Time: ${event.startTime}`}
                    EndTime={event.endTime ? `End Time: ${event.endTime}` : ""}
                  />
                </div>
              ))}
            </Slider>
          ) : (
            // If fewer than 3 events, render them in a single row with col-4
            upcomingEvents.map((event) => (
              <div key={event.id} className="col-md-6 col-lg-4 mb-3">
                <ModalToggler
                  w90="w-90"
                  Img={event.image}
                  Id={event.id}
                  Title={event.title}
                  Date={formatDate(event.date)} 
                  StartTime={`Start Time: ${event.startTime}`}
                  EndTime={event.endTime ? `End Time: ${event.endTime}` : ""}
                />
              </div>
            ))
          )}
        </div>
      </section>
    </section>
  );
};

export default UpcomingEvents;
