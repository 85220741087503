import React, { useEffect } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import { Helmet } from "react-helmet";
import Banner from "../assets/images/privacy-policy/Banner.webp";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Privacy Policy | Huntsville Islamic Center</title>
        <script src="https://widget-cdn.simplepractice.com/assets/integration-1.0.js"></script>
      </Helmet>
      <section id="website-responsive" className="px-0">
        <section
          className="container-fluid bg-common-banner"
          style={{ backgroundImage: `url(${Banner})` }}
        >
          <Header />
          <div className="fs-banner-title text-white fw-500 text-center py-5">
            <div className="py-md-5 mb-md-5">Privacy Policy</div>
          </div>
        </section>
        <div className="py-4 py-md-5" />

        <section className="container-fluid">
          <div className="row justify-content-evenly">
            <div className="col-11 col-md-10 mx-auto">
              <div className="pb-4 pb-md-5">
                <div className="pb-2 text-dark fs-ss-24 lh-sm fw-600">
                  Our Commitment to Privacy
                </div>
                <div className="text-dark fs-ss-18 fw-400">
                  Huntsville Islamic Center values the privacy of visitors and
                  members. We are dedicated to safeguarding personal data while
                  delivering spiritual and community services.
                </div>
              </div>

              <div className="pb-4 pb-md-5">
                <div className="pb-2 text-dark fs-ss-24 lh-sm fw-600">
                  How We Collect Information
                </div>
                <div className="text-dark fs-ss-18 fw-400">
                  We may collect non-personal data (e.g., browser details, IP
                  addresses) for technical purposes and user-provided personal
                  data (e.g., contact forms, service enrollments).
                </div>
              </div>

              <div className="pb-4 pb-md-5">
                <div className="pb-2 text-dark fs-ss-24 lh-sm fw-600">
                  How We Use Information
                </div>
                <div className="text-dark fs-ss-18 fw-400">
                  Collected data helps improve our services, facilitate
                  communications, and ensure security. Personal data is only
                  shared when necessary for service delivery or in compliance
                  with legal obligations.
                </div>
              </div>

              <div className="pb-4 pb-md-5">
                <div className="pb-2 text-dark fs-ss-24 lh-sm fw-600">
                  Cookies and Tracking
                </div>
                <div className="text-dark fs-ss-18 fw-400">
                  We use cookies to enhance user experience and analyze
                  website traffic. This does not include personally identifiable
                  information unless consent is provided.
                </div>
              </div>

              <div className="pb-4 pb-md-5">
                <div className="pb-2 text-dark fs-ss-24 lh-sm fw-600">
                  Security Measures
                </div>
                <div className="text-dark fs-ss-18 fw-400">
                  We implement industry-standard security practices to protect
                  user data.
                </div>
              </div>

              <div className="pb-4 pb-md-5">
                <div className="pb-2 text-dark fs-ss-24 lh-sm fw-600">
                  Third-Party Services
                </div>
                <div className="text-dark fs-ss-18 fw-400">
                  Our website may contain links to external sites. While we
                  strive to link only to reputable sites, we are not responsible
                  for the privacy practices of other websites. We recommend
                  reviewing their privacy policies separately.
                </div>
              </div>

              <div className="pb-4 pb-md-5">
                <div className="pb-2 text-dark fs-ss-24 lh-sm fw-600">
                  User Rights
                </div>
                <div className="text-dark fs-ss-18 fw-400">
                  Visitors can request data access, correction, or removal by
                  contacting us.
                </div>
              </div>

              <div className="pb-4 pb-md-5">
                <div className="pb-2 text-dark fs-ss-24 lh-sm fw-600">
                  Policy Updates
                </div>
                <div className="text-dark fs-ss-18 fw-400">
                  We may revise this policy to reflect updates in our
                  practices. Any changes will be communicated via our website.
                </div>
              </div>

              <div className="">
                <div className="pb-2 text-dark fs-ss-24 lh-sm fw-600">
                  Contact Information
                </div>
                <div className="text-dark fs-ss-18 fw-400">
                  If you have questions or concerns about this policy, please
                  reach out to us via the contact information page.
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="py-4 py-md-5" />

        <Footer />
      </section>
    </>
  );
};

export default PrivacyPolicy;
