import React from 'react'

const ModalToggler = (props) => {

  return (
    <>
    <div className={`${props.w90} cursor-pointer text-ss-primary px-0 shadow-ss-1 mb-4 mx-auto`} data-bs-toggle="modal" data-bs-target={`#${props.Id}`}>
      <img src={props.Img} className="w-100" alt="" />
    <div className="bg-events row justify-content-center gx-0">
      <div className="col-12 py-2">
        <div className="fs-ss-22 fw-600 align-text-line py-3 text-center">
          {props.Title}
        </div>
      </div>
      <div className="bg-ss-primary col-12 text-center fw-600 fs-ss-26 text-white lh-sm py-2">
        {props.Date}
      </div>
    </div>
    </div>
    
    </>
  )
}

export default ModalToggler