import React, { useEffect } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import Banner from "../assets/images/banners/CommunityActivity.webp";
import { Helmet } from "react-helmet";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Link } from "react-router-dom";

const CommunityActivities = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>Community Activities | Huntsville Islamic Center</title>
      </Helmet>
      <section id="website-responsive" className="px-0">
        <section className="container-fluid bg-common-banner" style={{ backgroundImage: `url(${Banner})` }}>
          <Header />
          <div className="fs-banner-title text-white fw-500 text-center py-5">
            <div className="py-md-5 mb-md-5">
              Community Activities
              <div className="py-3 py-lg-4" />
            </div>
          </div>
        </section>
        <div className="py-4 py-md-5" />
        <section className="container-fluid text-md-center col-11 col-md-10 mx-auto">
          <div className="">
            <div className="lh-sm">
              <div className="fs-ss-40 fw-600">
                Community Activities
              </div>
            </div>
            <div className="fs-ss-18 fw-400 mt-3">
              The Huntsville Islamic Center provides a diverse range of activities designed to foster spiritual growth, enhance Islamic knowledge, and build a stronger sense of community. These programs cater to individuals at all levels of understanding, offering opportunities for learning, reflection, and connection with fellow Muslims.
            </div>
          </div>
        </section>
        <div className="py-4 py-md-5" />
        <Tabs className="row justify-content-evenly gx-0 gy-5 px-md-5">
          <TabList className="fs-ss-30 fw-600 text-dark col-11 col-lg-3">
            <div className="sticky-top">
              <Tab className="d-block py-lg-5 py-3 cursor px-2 border-right">
                <div>
                  Tajweed Classes
                </div>
              </Tab>

              <Tab className="d-block py-lg-5 py-3 cursor px-2 border-right">
                <div>
                  Short Daily Reminder
                </div>
              </Tab>
            </div>
          </TabList>

          <div className="col-11 col-lg-7">
            <TabPanel>
              <section className="container-fluid">
                <div className="">
                  <div className="fs-ss-40 fw-600">
                    Tajweed Classes - Brothers
                  </div>
                  <div className="fs-ss-18 fw-400 mt-3">
                    These classes are ideal for brothers who wish to deepen their connection with the Quran and improve their recitation in a focused, structured setting.
                  </div>
                  <div className="py-2" />
                  <div className="fs-ss-20 fw-500 text-ss-primary">Schedule & Location:</div>
                  <div>Saturdays and Sundays: After Fajr (morning) prayer at the HIC Prayer Hall</div>
                  <div className="py-2" />
                  <div className="fs-ss-20 fw-500 text-ss-primary">Program Includes:</div>
                  <ul>
                    <li>Quranic Recitation: Practice reciting the Quran with fluency and correct pronunciation.</li>
                    <li>Tajweed Rules: Learn and apply the rules of Tajweed to enhance the beauty and accuracy of Quranic recitation.</li>
                  </ul>
                </div>
              </section>
            </TabPanel>

            <TabPanel>
              <section className="container-fluid">
                <div className="">
                  <div className="fs-ss-40 fw-600">
                    Short Daily Reminder - Brothers & Sisters
                  </div>
                  <div className="fs-ss-18 fw-400 mt-3">
                    Every evening, join us for a brief yet impactful reminder that provides spiritual guidance, encouragement, and reflection. These reminders are a perfect way to end your day with renewed faith and inspiration, focusing on practical lessons from Islamic teachings.
                  </div>
                  <div className="py-2" />
                  <div className="fs-ss-20 fw-500 text-ss-primary">Schedule & Location:</div>
                  <div className="pt-2">Daily after Isha prayers at the HIC Prayer Hall</div>
                  <div className="pt-2">
                    We also offer a multitude of youth programming. For more information and a calendar of events, <Link className="text-decoration-none text-ss-primary fw-500" to="/calendar">  click here. </Link>
                  </div>
                </div>
              </section>
            </TabPanel>
          </div>
        </Tabs>
        <div className="py-4 py-md-5" />
        <section className="container-fluid text-md-center col-11 col-md-10 mx-auto">
          <div className="">
            <div className="fs-ss-40 fw-600">
              Have Questions or Need Guidance?
            </div>
            <div className="fs-ss-18 fw-400 mt-3">
              For further information about these programs, please email <a className="text-ss-primary text-decoration-none fw-500" href="mailto:religious@huntsvilleislamiccenter.org">religious@huntsvilleislamiccenter.org</a>. We encourage everyone to take advantage of these enriching opportunities to grow in faith and understanding.
            </div>
          </div>
        </section>
        <div className="py-4 py-md-5" />
        <Footer />
      </section>
    </>
  );
}

export default CommunityActivities;
