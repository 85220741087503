import React, { useEffect, useState } from "react";
import Slider from 'react-slick';
import eventsData from "../events/ramadan_events.json";  // Import events data from events.json
import ModalContent from './ModalContent';
import ModalToggler from './ModalToggler';

const RamadanEvents = () => {
  const [events, setEvents] = useState([]); // Rename to 'events' instead of 'upcomingEvents'

  // Helper function to format the date without the weekday
  const formatDate = (dateString) => {
    const localDate = new Date(dateString + "T00:00:00"); // Adjust to local time
    const options = { year: 'numeric', month: 'long', day: 'numeric' }; // Only show month, day, year
    return localDate.toLocaleDateString('en-US', options); // Adjust locale as needed
  };

  // Instead of filtering for upcoming events, just set all events
  const loadEvents = () => {
    setEvents(eventsData); // Load all events, no filtering for upcoming/past
  };

  useEffect(() => {
    loadEvents(); // Load events when the component mounts
  }, []);

  // Settings for the Slick slider
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Always try to show 3 items
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 7000,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: true,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: true,
        }
      }
    ]
  };

  return (
    <section className="container-fluid">
      <div className="pb-4 text-center px-4 px-md-0">
        <div className="fs-ss-40 fw-600">
          Ramadan Events
        </div>
      </div>
      <section className="container-lg">
        <div className="row justify-content-center px-md-5">
          {/* Modal Content for Events */}
          {events.length > 0 ? (
            events.map((event) => (
              <ModalContent
                key={event.id}
                Img={event.image}
                Id={event.id}
                Title={event.title}
                Date={formatDate(event.date)} 
                StartTime={`Start Time: ${event.startTime}`}
                EndTime={event.endTime ? `End Time: ${event.endTime}` : ""}
              />
            ))
          ) : (
            <p className="text-center fs-ss-18 fw-500">No events available.</p>
          )}

          {/* Conditionally render the Slider */}
          {events.length >= 3 ? (
            <Slider {...settings}>
              {events.map((event) => (
                <div key={event.id} className="slider-item">
                  <ModalToggler
                    w90="w-90"
                    Img={event.image}
                    Id={event.id}
                    Title={event.title}
                    Date={formatDate(event.date)} 
                    StartTime={`Start Time: ${event.startTime}`}
                    EndTime={event.endTime ? `End Time: ${event.endTime}` : ""}
                  />
                </div>
              ))}
            </Slider>
          ) : (
            // If fewer than 3 events, render them in a single row with col-4
            events.map((event) => (
              <div key={event.id} className="col-md-6 col-lg-4 mb-3">
                <ModalToggler
                  w90="w-90"
                  Img={event.image}
                  Id={event.id}
                  Title={event.title}
                  Date={formatDate(event.date)} 
                  StartTime={`Start Time: ${event.startTime}`}
                  EndTime={event.endTime ? `End Time: ${event.endTime}` : ""}
                />
              </div>
            ))
          )}
        </div>
      </section>
    </section>
  );
};

export default RamadanEvents;
