import React, { useEffect } from "react";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";
import Img1 from "../assets/images/new-muslim/Img1.webp";
import Banner from "../assets/images/banners/NewMuslim.webp";
import { Helmet } from "react-helmet";

const Reverts = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Reverts | Huntsville Islamic Center
        </title>
      </Helmet>
      <section id="website-responsive" className="px-0">
        <section className="container-fluid bg-common-banner" style={{ backgroundImage: `url(${Banner})` }}>
          <Header />
          <div className="fs-banner-title text-white fw-500 text-center py-5">
            <div className="py-md-5 mb-md-5">
              Reverts
              <div className="py-3 py-lg-4" />
            </div>
          </div>
        </section>
        <div className="py-4 py-md-5" />
        <section className="container-fluid text-md-center col-11 col-lg-10 mx-auto">
          <div className="">
            <div className="lh-sm">
              <div className="fs-ss-40 fw-600 ">
                A Warm Welcome to Our Community
              </div>
            </div>
            <div className="fs-ss-18 fw-400 mt-3">
              The Huntsville Islamic Center is delighted to welcome new Muslims into our growing community. We understand that embracing a new faith can be both exciting and challenging, so we are here to support you every step of the way. Our programs are designed to help you deepen your understanding of Islam and integrate into the Muslim community with confidence and ease.
            </div>
          </div>
        </section>
        <div className="py-4 py-md-5" />
        <section className="container-fluid">
          <div className="row justify-content-evenly align-items-center gy-4">
            <div className="col-11 col-md-7 col-lg-5">
              <div className="lh-sm">
                <div className="fs-ss-40 fw-600 ">
                  Weekly Classes on the Basics of Islam
                </div>
              </div>
              <div className="fs-ss-18 fw-400 mt-3">
                Our weekly classes (Sundays, 12 PM) provide an excellent foundation for new Muslims and those curious about Islam. These sessions cover:
                <ul className="">
                  <li className="my-3"><strong className="fw-500">Core Beliefs:</strong> Explore the fundamental tenets of Islamic faith.</li>
                  <li className="mb-3"><strong className="fw-500">Everyday Practice:</strong> Learn how to perform ablution, perform your daily prayers, and more.</li>
                  <li className="mb-3"><strong className="fw-500">Meaning of the Quran:</strong> Memorize and discover the profound teachings and messages of the Holy Quran.</li>
                </ul>
              </div>
            </div>
            <div className="col-8 col-md-4">
              <img src={Img1} className="w-100 px-lg-5" alt="" />
            </div>
          </div>
        </section>
        <div className="py-4 py-md-5" />
        {/* New Muslim Support Group Section Updated */}
        <section className="container-fluid text-md-center col-11 col-lg-10 mx-auto">
          <div className="">
            <div className="lh-sm">
              <div className="fs-ss-40 fw-600 ">
                New Muslim Support Group
              </div>
            </div>
            <div className="fs-ss-18 fw-400 mt-3">
              Whether you're just beginning your journey or seeking to strengthen your knowledge, the New Muslim Support Group is here to assist. As part of our services, we provide access to a multitude of helpful resources and tools to deepen your understanding of Islam. Additionally, we can help you connect with experienced mentors who can offer personalized guidance and support. We are also committed to helping you connect with the broader Muslim community in a welcoming and inclusive environment. If you're a new Muslim or simply interested in learning more about Islam, we encourage you to connect with us. You can email us at{" "}
              <a className="text-decoration-none text-ss-primary fw-500" href="mailto:religious@huntsvilleislamiccenter.org">
                religious@huntsvilleislamiccenter.org
              </a> for more information.
            </div>
          </div>
        </section>
        <div className="py-4 py-md-5" />
        <Footer />
      </section>
    </>
  );
};

export default Reverts;
