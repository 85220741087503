import React, { useEffect } from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap'
import '../src/assets/css/style.css';
import "aos/dist/aos.css"
import 'react-toastify/dist/ReactToastify.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import 'react-tabs/style/react-tabs.css';

// Pages
import Error404 from './pages/Error404';
import Home from './pages/Home'
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import FAQs from './pages/FAQs';
import BurialServices from './pages/BurialServices';
import Events from './pages/Events';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Modal from './components/Modal';
import GeneralForms from './pages/GeneralForms';
import CommunityActivities from './pages/CommunityActivities';
import YouthGroup from './pages/YouthGroup';
import WeekendIslamicSchool from './pages/WeekendIslamicSchool';
import CommunityOutreach from './pages/CommunityOutreach';
import ZakatDistribution from './pages/ZakatDistribution';
import Resources from './pages/Resources';
import MarriageCeremonies from './pages/MarriageCeremonies';
import GoogleCalendar from './pages/GoogleCalendar';
import Ramadan from './pages/Ramadan';
import Reverts from './pages/Reverts';

const App = () => {
  return (
    <>
    
      <Routes>
        <Route path="*" element={<Error404/>} />
        <Route path="/" element={<Home/>} />
        <Route path="/about-us" element={<AboutUs/>} />
        <Route path="/events" element={<Events/>} />
        <Route path="/community-activities" element={<CommunityActivities/>} />
        <Route path="/youth-group" element={<YouthGroup/>} />
        <Route path="/weekend-islamic-school" element={<WeekendIslamicSchool/>} />
        <Route path="/marital-ceremonies" element={<MarriageCeremonies/>} />
        <Route path="/burial-services" element={<BurialServices/>} />
        <Route path="/reverts" element={<Reverts/>} />
        <Route path="/community-outreach" element={<CommunityOutreach/>} />
        <Route path="/zakat-and-sadaqah" element={<ZakatDistribution/>} />
        <Route path="/general-forms" element={<GeneralForms/>} />
        <Route path="/resources" element={<Resources/>} />
        <Route path="/faqs" element={<FAQs/>} />
        <Route path="/contact-us" element={<ContactUs/>} />
        <Route path="/ramadan" element={<Ramadan/>} />
        <Route path="/calendar" element={<GoogleCalendar/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicy/>} />

        <Route path='/new-muslims' element={ <Navigate to="/reverts" /> }/>
      </Routes>
      <Modal/>
 
    </>
  );
}

export default App;
