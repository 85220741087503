import React from "react";
import { Navbar, NavbarBrand, NavbarOffcanvas, NavbarToggle, OffcanvasBody, OffcanvasHeader, OffcanvasTitle } from "react-bootstrap";
import NavBarLink from "./NavBarLink";
import NavBarLink2 from "./NavBarLink2";
import Logo from "../../assets/images/layout/headerlogo.webp"; 
import DropdownMenu from "./DropdownMenu";
import { Link } from "react-router-dom";
import NavBarLink3 from "./NavBarLink3";

const Header = () => {
  return (
    <> 
    <section className="container-fluid fs-ss-links bg-ss-transparent">
    <section className="container-lg">
      <Navbar expand="lg" className="">
          <NavbarBrand className='navbar-brand'>
            <Link className="" to="/">
            <img
            src={Logo}
            alt="Logo"
            className="header-logo"
            />
            </Link>
            </NavbarBrand>
            <NavbarToggle aria-controls={`offcanvasNavbar-expand-xl`} className='border-0 rounded-0 toggle-icon bg-white fs-ss-16 border-0'/>
            <NavbarOffcanvas
            id={`offcanvasNavbar-expand-xl`}
            aria-labelledby={`offcanvasNavbarLabel-expand-xl`}
            placement="start"
            className="navbar-offcanvas"
            >
              <OffcanvasHeader closeButton className='bg-ss-offcanvas-primary px-4'>
                <OffcanvasTitle className='' id={`offcanvasNavbarLabel-expand-xl`}>
                  <Link
                  className=""
                  to="/"
                  >
                    <img
                    src={Logo}
                    alt="Logo"
                    className="offcanvas-logo"
                    />
                    </Link>
                    </OffcanvasTitle>
                    </OffcanvasHeader>
                    <OffcanvasBody className="bg-ss-offcanvas-primary">
                      <ul className="navbar-nav ms-auto align-items-lg-center mt-md-3">
                      <NavBarLink
                        Name="About Us"
                        Link_To="about-us"
                        />
                        
                        <NavBarLink3
                        Name="Events"
                        Link_To="events"
                        DropdownMenu={
                        <>
                        <DropdownMenu
                        DropdownItems={
                          <>
                          <div className=""> <Link className="text-dark text-decoration-none hover-ss-black-primary" to="/events"> Upcoming Events </Link> </div>
                          <div className="pt-3"> <Link className="text-dark text-decoration-none hover-ss-black-primary" to="/calendar"> Calendar </Link> </div>
                          </>
                        }
                        />
                        </>}
                        /> 

                        <NavBarLink
                        Name="Ramadan"
                        Link_To="ramadan"
                        />
                        
                        <NavBarLink3
                        Name="Programs"
                        Link_To="/programs"
                        DropdownMenu={
                        <>
                        <DropdownMenu
                        DropdownItems={
                          <>
                          <div className=""> <Link className="text-dark text-decoration-none hover-ss-black-primary" to="/community-activities"> Community Activities </Link> </div>
                          <div className="pt-3"> <Link className="text-dark text-decoration-none hover-ss-black-primary" to="/youth-group"> Youth Group </Link> </div>
                          <div className="pt-3"> <Link className="text-dark text-decoration-none hover-ss-black-primary" to="/weekend-islamic-school"> Weekend Islamic School </Link> </div>
                          <div className="pt-3"> <a className="text-dark text-decoration-none hover-ss-black-primary" target="_blank" href="https://islamicacademyofhuntsville.org"  > Islamic Academy of Huntsville </a> </div>
                          </>
                        }
                        />
                        </>}
                        /> 
                                                                        
                        <NavBarLink3
                        Name="Services"
                        Link_To="services"
                        DropdownMenu={
                        <>
                        <DropdownMenu
                        DropdownItems={
                          <>
                          <div className=""> <Link className="text-dark text-decoration-none hover-ss-black-primary" to="/marital-ceremonies"> Marriage Ceremonies </Link> </div>
                          <div className="pt-3"> <Link className="text-dark text-decoration-none hover-ss-black-primary" to="/burial-services"> Burial Services </Link> </div>
                          <div className="pt-3"> <Link className="text-dark text-decoration-none hover-ss-black-primary" to="/reverts"> Reverts </Link> </div>
                          <div className="pt-3"> <Link className="text-dark text-decoration-none hover-ss-black-primary" to="/community-outreach"> Community Outreach </Link> </div>
                          <div className="pt-3"> <Link className="text-dark text-decoration-none hover-ss-black-primary" to="/zakat-and-sadaqah"> Zakat and Sadaqah </Link> </div> 
                          <div className="pt-3"> <a className="text-dark text-decoration-none hover-ss-black-primary" target="_blank" href="https://forms.gle/KJu1kzsSce6bTW958"  > Hardship Form </a> </div>
                          </>
                        }
                        />
                        </>}
                        /> 

                      <NavBarLink
                        Name="Resources"
                        Link_To="resources"
                        />

                        {/* <NavBarLink3
                        Name="Resources"
                        Link_To="resources"
                        DropdownMenu={
                        <>
                        <section className="container-fluid py-3">
                          <div className="fs-ss-16 fw-500 text-dark">
                            <div className=""> <Link className="text-dark text-decoration-none hover-ss-black-primary" to="/general-forms"> General Forms </Link> </div>
                            <div className="pt-2"> <Link className="text-dark text-decoration-none hover-ss-black-primary" to="/membership-forms"> Membership Forms </Link> </div>
                          </div>
                          </section>
                        </>}
                        />                              */}

                      <NavBarLink
                        Name="Contact Us"
                        Link_To="contact-us"
                        />

                        <a target="_blank" href="https://checkout.square.site/merchant/WKRSEAKCJHK6E/checkout/VHNIUNBCZZT77TEG77DKXULW" className="d-none d-lg-block col-5 col-md text-decoration-none ms-md-3 mt-3 mt-lg-0 hover-ss-donate rounded-5 border-0 py-1 px-5 fs-ss-links text-dark fw-500">
                          Donate
                        </a>
                        <a target="_blank" href="https://checkout.square.site/merchant/WKRSEAKCJHK6E/checkout/VHNIUNBCZZT77TEG77DKXULW" className="d-block d-lg-none col-12 text-center text-decoration-none ms-md-3 mt-3 mt-lg-0 hover-ss-donate rounded-2 border-0 py-1 px-5 fs-ss-18 text-dark fw-500">
                          Donate Now
                        </a>
                        </ul>

                        {/* <ul className="navbar-nav align-items-center pt-2 pt-md-0">
                        </ul>                         */}
                        </OffcanvasBody>
                        </NavbarOffcanvas>
                        </Navbar>
                        </section>
                        </section>
    </>
  );
}

export default Header

