import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import eventsData from "./events.json";
import ModalToggler from "../UpcomingEvents/ModalToggler";
import ModalContent from "../UpcomingEvents/ModalContent";

const EventTabs = () => {
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);

  // Helper function to sort and separate events into past and upcoming
  const separateEvents = (events) => {
    const currentDate = new Date();

    const upcoming = events
      .filter((event) => new Date(event.date) >= currentDate)
      .sort((a, b) => new Date(a.date) - new Date(b.date)); // Sort ascending for upcoming

    const past = events
      .filter((event) => new Date(event.date) < currentDate)
      .sort((a, b) => new Date(b.date) - new Date(a.date)); // Sort descending for past

    setUpcomingEvents(upcoming);
    setPastEvents(past);
  };

  useEffect(() => {
    separateEvents(eventsData);
  }, []);

  // Helper function to format full date
  const formatDate = (dateString) => {
    const localDate = new Date(dateString + "T00:00:00"); // Adjust to local time
    const options = { year: 'numeric', month: 'long', day: 'numeric' }; // Only show month, day, year
    return localDate.toLocaleDateString('en-US', options); // Adjust locale as needed
  };
  

  return (
    <section id="events" className="container-fluid">
      <Tabs>
        <TabList className="nav nav-tabs fw-600 fs-ss-20">
          <Tab className="nav-item ms-md-auto px-4 center">
            <a className="nav-link" href="#">Upcoming Events</a>
          </Tab>
          <div className="py-2 d-block d-md-none" />
          <Tab className="nav-item me-md-auto px-4 center">
            <a className="nav-link" href="#">Past Events</a>
          </Tab>
        </TabList>

        {/* Upcoming Events Tab */}
        <TabPanel className="mt-5">
          <h2 className="my-4 text-center">Upcoming Events</h2>
          {upcomingEvents.length > 0 ? (
            <div className="row col-12 mx-auto justify-content-center px-lg-5 gap-4">
              {upcomingEvents.map((event) => (
                <div key={event.id} className="col-md-5 col-lg-3">
                  <ModalToggler
                    Img={event.image}
                    Id={event.id}
                    Title={event.title}
                    Date={formatDate(event.date)} 
                    StartTime={`Start Time: ${event.startTime}`}
                    EndTime={event.endTime ? `End Time: ${event.endTime}` : ""}
                  />
                  <ModalContent
                    Img={event.image}
                    Id={event.id}
                    Title={event.title}
                    Date={formatDate(event.date)} 
                    StartTime={`Start Time: ${event.startTime}`}
                    EndTime={event.endTime ? `End Time: ${event.endTime}` : ""}
                  />
                </div>
              ))}
            </div>
          ) : (
            <p className="text-center fs-ss-18 fw-500">No upcoming events.</p>
          )}
        </TabPanel>

        {/* Past Events Tab */}
        <TabPanel className="mt-5">
          <h2 className="my-4 text-center">Past Events</h2>
          {pastEvents.length > 0 ? (
            <div className="row col-12 mx-auto justify-content-center px-lg-5 gap-4">
              {pastEvents.map((event) => (
                <div key={event.id} className="col-md-5 col-lg-3">
                  <ModalToggler
                    Img={event.image}
                    Id={event.id}
                    Title={event.title}
                    Date={formatDate(event.date)}
                    StartTime={`Start Time: ${event.startTime}`}
                    EndTime={event.endTime ? `End Time: ${event.endTime}` : ""}
                  />
                  <ModalContent
                    Img={event.image}
                    Id={event.id}
                    Title={event.title}
                    Date={formatDate(event.date)}
                    StartTime={`Start Time: ${event.startTime}`}
                    EndTime={event.endTime ? `End Time: ${event.endTime}` : ""}
                  />
                </div>
              ))}
            </div>
          ) : (
            <p className="text-center fs-ss-18 fw-500">No past events.</p>
          )}
        </TabPanel>
      </Tabs>
    </section>
  );
};


export default EventTabs;
